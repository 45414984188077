import { useRouter } from 'next/router';
import {
    AnnouncementTypes,
    useFeatureAnnouncements,
} from '../../hooks/useFeatureAnnouncements';
export const FeatureAnnouncements = () => {
    const {
        data: announcements,
        isValidating,
        upsertOne,
    } = useFeatureAnnouncements();
    const { push } = useRouter();

    const dismissed = (announcementId: AnnouncementTypes) =>
        announcements?.some(
            (announcement) =>
                announcement.id === announcementId && announcement.dismissed,
        );

    if (isValidating && !announcements?.length) {
        return null;
    }

    return null; 
};

import moment from 'moment';
import { getMonthName } from '../utils/date';
import ArrowIcon from '../../public/gfx/uiIcons/collapsedArrow.svg';
import styles from './CustomDatePickerHeader.module.css';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

export const CustomDatePickerHeader: React.FC<
    ReactDatePickerCustomHeaderProps
> = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}) => (
    <div className={styles.headerContainer}>
        <p className={styles.dateString} data-testid="datepicker-header">
            {getMonthName(moment(date).month())}{' '}
            <span className={styles.dateStringYear}>{moment(date).year()}</span>
        </p>
        <div className={styles.changeMonthBtnContainer}>
            {!prevMonthButtonDisabled && (
                <button
                    onClick={decreaseMonth}
                    className={styles.changeMonthBtn}
                >
                    <ArrowIcon width="30" height="30" stroke="#020C16" />
                </button>
            )}
            {!nextMonthButtonDisabled && (
                <button
                    onClick={increaseMonth}
                    className={styles.changeMonthBtn}
                >
                    <ArrowIcon
                        className="rotated180"
                        width="30"
                        height="30"
                        stroke="#020C16"
                    />
                </button>
            )}
        </div>
    </div>
);

import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { apiClient } from '../../apiClient/apiClient';
import {
    CreatedOrderDto,
    LineItemDto,
    OrderStatus,
    ApiError,
} from '../../apiClient/generated';
import { LineItem, OrderToDisplay, RFQ } from '../../types/order.types';
import { OrderTypes } from '../../context/OrderTypes';
import { trackingEvents } from '../../context/Tracking';
import { db } from '../../db/db';
import { dataFlowEventHub } from '../../events/dataFlowEvents';

export const prepareOrderForSubmit = (
    orderToDisplay: OrderToDisplay,
): RFQ | undefined => {
    if (!orderToDisplay?.rfq) {
        return;
    }

    const rfq = { ...orderToDisplay.rfq };

    rfq.lineItems = rfq.lineItems?.filter((item) => item.quantity > 0);
    rfq.lineItems = updatePricesInLineItems(rfq.lineItems);

    // Remove extra properties API might not understand
    delete rfq.id;
    delete rfq.origin;

    rfq.lineItems = rfq.lineItems.filter((item) => item.quantity > 0);
    return rfq;
};

export const updatePricesInLineItems = (lineItems: LineItem[]) => {
    return lineItems.map((lineItem) => {
        if (lineItem.estimatedPrice) {
            return {
                ...lineItem,
                price: lineItem.estimatedPrice,
            };
        }
        return lineItem;
    });
};

export enum OrderSubmissionResult {
    success,
    apiOffline,
    activeOrderExists,
    requestFailed,
    unavailableProducts,
}

export type OrderSubmissionResultWrapper = {
    result: OrderSubmissionResult;
    unavailableItemNumbers?: string[];
};

const createOrder = async (orderToDisplay: OrderToDisplay, rfq: RFQ) => {
    return apiClient.createOrder({
        ...rfq,
        // @TODO: Make sure agent if prefilled, since it is optional
        // on the frontend, but not yet fully optional on the backend
        agent: {
            name: rfq.agent?.name || 'N/A',
            city: rfq.agent?.city || 'N/A',
            phone: rfq.agent?.phone || '0000',
            email: rfq.agent?.email || 'agent@example.org',
        },
        type: orderToDisplay?.type ?? OrderTypes.provision,
        deliveryDate: dateToISOStringWithoutTime(rfq.deliveryDate),
        deliveryPort: rfq.deliveryPort ?? {},
        departureDate: dateToISOStringWithoutTime(
            rfq.departureDate ?? new Date('2001-01-01'),
        ),
        contractCurrency: rfq.contractCurrency ?? 'USD',
        lineItems: rfq.lineItems.map(
            (item): LineItemDto => ({
                itemNumber: item.itemNumber,
                quantity: item.quantity,
                comment: item.comment,
                unitOfMeasure: item.unitOfMeasure,
                packSize: item.confirmedPackSize ?? item.estimatedPackSize ?? 0,
                price: item.confirmedPrice ?? item.estimatedPrice ?? 0,
                itemName: item.itemName ?? '',
            }),
        ),
        localId: orderToDisplay.localOrderId,
    });
};

const updateOrder = async (orderToDisplay: OrderToDisplay, rfq: RFQ) => {
    if (!orderToDisplay.orderId) {
        return;
    }
    const response = await apiClient.updateOrder(orderToDisplay?.orderId, {
        ...rfq,
        type: orderToDisplay.type,
        comment: rfq.comment,
        coveringDays: rfq.coveringDays,
        manning: rfq.manning,
        deliveryPort: rfq.deliveryPort ?? {},
        nextPort: rfq.nextPort,
        contractCurrency: rfq.contractCurrency ?? 'USD',
        lineItems: rfq.lineItems.map(
            (item): LineItemDto => ({
                itemNumber: item.itemNumber,
                quantity: item.quantity,
                comment: item.comment,
                unitOfMeasure: item.unitOfMeasure,
                packSize: item.confirmedPackSize ?? item.estimatedPackSize ?? 0,
                price: item.confirmedPrice ?? item.estimatedPrice ?? 0,
                itemName: item.itemName ?? '',
            }),
        ),
        deliveryDate: dateToISOStringWithoutTime(rfq.deliveryDate),
        agent: {
            name: rfq.agent?.name || 'N/A',
            city: rfq.agent?.city || 'N/A',
            phone: rfq.agent?.phone || '0000',
            email: rfq.agent?.email || 'agent@example.org',
        },
        departureDate: dateToISOStringWithoutTime(
            rfq.departureDate ?? new Date(),
        ),
        localId: orderToDisplay.localOrderId,
    });

    await db.orders
        .where({ orderId: orderToDisplay.orderId })
        .modify({ status: OrderStatus.Created });
    dataFlowEventHub.emit('orderChanged', orderToDisplay.orderId);
    dataFlowEventHub.emit('ongoingOrderChanged', orderToDisplay.type);
    dataFlowEventHub.emit('ordersChanged');

    return response;
};

const sendDraftSentMixpanelEvent = (
    orderToDisplay: OrderToDisplay,
    apiResponse: CreatedOrderDto,
    rfq: RFQ,
) => {
    mixpanel.track(trackingEvents.draftSent, {
        orderType: orderToDisplay?.type ?? OrderTypes.provision,
        createdOrderId: apiResponse?.orderId,
        portCode: rfq.deliveryPort?.portCode,
        fromOfflineStorage: false,
    });
};

export const submitOrder = async (
    orderToDisplay: OrderToDisplay,
    rfq: RFQ,
): Promise<OrderSubmissionResultWrapper> => {
    try {
        if (!orderToDisplay.orderId) {
            const apiResponse = await createOrder(orderToDisplay, rfq);
            sendDraftSentMixpanelEvent(orderToDisplay, apiResponse, rfq);
            dataFlowEventHub.emit('ordersChanged');
            return { result: OrderSubmissionResult.success };
        } else {
            await updateOrder(orderToDisplay, rfq);
            dataFlowEventHub.emit('ordersChanged');
            return { result: OrderSubmissionResult.success };
        }
    } catch (err) {
        if ((err as ApiError).status) {
            const { status, body } = err as ApiError;

            // todo: probably we should create separated module to handle API errors. maybe we could even use auto generated api to that?
            switch (status) {
                case 502:
                    return { result: OrderSubmissionResult.apiOffline };
                case 418:
                    return {
                        result: OrderSubmissionResult.requestFailed,
                    };
                case 400:
                    if (
                        body?.includes(
                            'Some of the line product numbers do not exist',
                        )
                    ) {
                        const unavailableItemNumbers: string[] = [];
                        try {
                            const parsedErrorBody = JSON.parse(body);
                            const parsedErrorMessage = JSON.parse(
                                parsedErrorBody.detail,
                            );
                            unavailableItemNumbers.push(
                                ...(parsedErrorMessage?.MissingNumbers ?? []),
                            );
                        } catch (error) {
                            console.error(error);
                        }
                        return {
                            result: OrderSubmissionResult.unavailableProducts,
                            unavailableItemNumbers,
                        };
                    }
                    return { result: OrderSubmissionResult.requestFailed };
                default:
                    return { result: OrderSubmissionResult.requestFailed };
            }
        } else {
            return { result: OrderSubmissionResult.requestFailed };
        }
    }
};

export const dateToISOStringWithoutTime = (date: Date): string => {
    const dateString = moment(date).startOf('day').toISOString(true);
    const [formattedDate] = dateString.split('T');
    return `${formattedDate}T23:59:59.999Z`;
};

import styles from './StarRating.module.css';
import StarIcon from '../../public/gfx/uiIcons/starRating.svg';
import React, { useState } from 'react';
import { PerformanceRating, RatingKey } from '../../types/receivedOrder.types';
import { useReceivedOrder } from '../../hooks/useReceivedOrder';
import useOrderType from '../../hooks/useOrderType';

export enum Rating {
    notRelevant = 'Not Relevant',
    veryBad = 'Very bad',
    bad = 'Bad',
    okay = 'Okay',
    good = 'Good',
    excellent = 'Excellent',
}

export interface Star {
    rateNumber: PerformanceRating;
    rateText?: Rating;
}

interface StarRatingProps {
    setSelected: React.Dispatch<React.SetStateAction<PerformanceRating | undefined>>;
    selected: PerformanceRating | undefined;
    smaller?: boolean;
    ratingKey: RatingKey;
    onContinue?: () => void;
    setAutomaticNextPage?: React.Dispatch<React.SetStateAction<boolean>>;
    allowNotRelevant?: boolean; 
}

const StarRating: React.FC<StarRatingProps> = ({
    setSelected,
    selected,
    smaller,
    ratingKey,
    onContinue,
    setAutomaticNextPage,
    allowNotRelevant = false, 
}) => {
    const { orderIdFromQuery } = useOrderType();
    const { updatePerformanceReportRating } = useReceivedOrder(
        Number(orderIdFromQuery),
    );

    const [hoveredStar, setHoveredStar] = useState<PerformanceRating>();
    const [hoveredNotRelevant, setHoveredNotRelevant] = useState<boolean>(false);

    const stars: Star[] = [
        ...(allowNotRelevant ? [{ rateNumber: 0 as PerformanceRating, rateText: Rating.notRelevant }] : []),
        { rateNumber: 1 as PerformanceRating, rateText: Rating.veryBad },
        { rateNumber: 2 as PerformanceRating, rateText: Rating.bad },
        { rateNumber: 3 as PerformanceRating, rateText: Rating.okay },
        { rateNumber: 4 as PerformanceRating, rateText: Rating.good },
        { rateNumber: 5 as PerformanceRating, rateText: Rating.excellent },
    ];

    const renderImage = (rateNumber: PerformanceRating, isSelected: boolean, isHovered: boolean, isNotRelevant: boolean) => {
        const fill = `${isHovered || isSelected ? '#276ef1' : '#f4f5f6'}`;
        const width = `${!smaller ? '68px' : '39px'}`;
        const height = `${!smaller ? '64px' : '36px'}`;
        
        if (isNotRelevant) {
            const notRelevantFill = isHovered || isSelected ? '#ff5c5c' : '#f4f5f6'; 
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={width}
                    height={height}
                    fill="currentColor"
                >
                    <circle cx="12" cy="12" r="10" stroke={notRelevantFill} strokeWidth="4" fill="none" />
                    <line x1="4" y1="4" x2="20" y2="20" stroke={notRelevantFill} strokeWidth="4" />
                </svg>
            );
        }
        return <StarIcon fill={fill} width={width} height={height} />;
    };

    const handleSelect = async (selectedItem: PerformanceRating) => {
        setSelected(selectedItem);
        await updatePerformanceReportRating(ratingKey, selectedItem);
        if (selectedItem === 0 || selectedItem > 2) { 
            setAutomaticNextPage ? setAutomaticNextPage(true) : '';
            if (onContinue) {
                setTimeout(() => {
                    onContinue();
                }, 500);
            }
        }
    };

    const handleStarHover = (rateNumber: PerformanceRating | undefined) => {
        setHoveredStar(rateNumber);
    };

    const handleNotRelevantHover = (isHovered: boolean) => {
        setHoveredNotRelevant(isHovered);
    };

    return (
        <div className={styles.starsContainer}>
            {allowNotRelevant && (
                <button
                    className={[
                        styles.starComponent,
                        smaller && styles.active,
                    ].join(' ')}
                    onClick={() => handleSelect(0)}
                    onMouseEnter={() => handleNotRelevantHover(true)}
                    onMouseLeave={() => handleNotRelevantHover(false)}
                    aria-label="Not Relevant"
                >
                    {renderImage(0, 0 === selected, hoveredNotRelevant, true)}
                    <p
                        className={[
                            styles.text,
                            smaller && styles.smallerComponentText,    
                            (hoveredNotRelevant || 0 === selected) ? styles.redText : '',
                        ].join(' ')}
                    >
                        {Rating.notRelevant}
                    </p>
                </button>
            )}
            {stars.filter(star => star.rateNumber !== 0).map((star, key) => (
                <button
                    className={[
                        styles.starComponent,
                        smaller && styles.active,
                    ].join(' ')}
                    onClick={() => handleSelect(star.rateNumber)}
                    onMouseEnter={() => handleStarHover(star.rateNumber)}
                    onMouseLeave={() => handleStarHover(undefined)}
                    key={key}
                    aria-label={`Rate ${star.rateNumber} stars (${star.rateText})`}
                >
                    {renderImage(
                        star.rateNumber,
                        star.rateNumber <= Number(selected),
                        star.rateNumber <= Number(hoveredStar),
                        false
                    )}
                    <p
                        className={[
                            styles.text,
                            smaller && styles.smallerComponentText,
                            star.rateNumber === Number(hoveredStar) && styles.blueText,
                            star.rateNumber === Number(selected) && styles.blueText,
                            !selected && !hoveredStar && styles.darkGreyText,
                            star.rateNumber < Number(hoveredStar) && styles.darkGreyText,
                        ].join(' ')}
                    >
                        {star.rateText}
                    </p>
                </button>
            ))}
        </div>
    );
};

export default StarRating;
import styles from './SummaryRow.module.css';
import { ReactElement } from 'react';

export const SummaryRow = ({
    label,
    value,
    backgroundColor,
    strongBottomBorder,
    strongTopBorder,
    labelHelper,
    valueHelper,
}: {
    label: string;
    labelHelper?: string;
    valueHelper?: string | ReactElement;
    value: string;
    backgroundColor?: 'grey';
    strongBottomBorder?: boolean;
    strongTopBorder?: boolean;
}) => {
    const getBackgroundColor = () => {
        switch (backgroundColor) {
            case 'grey':
                return '#F4F5F6';
            default:
                return 'transparent';
        }
    };

    return (
        <div
            style={{
                backgroundColor: getBackgroundColor(),
                borderBottom: strongBottomBorder ? '2px solid black' : '',
                borderTop: strongTopBorder ? '2px solid black' : '',
                
            }}
            className={[styles.container].join(' ')}
        >
            <div className={styles.textContainer}>
                <p className={styles.label}>{label}</p>
                {labelHelper && <p className={styles.helper}>{labelHelper}</p>}
            </div>
            <div className={styles.value}>
                <p>{value}</p>
                {valueHelper && <p className={styles.helper}>{valueHelper}</p>}
            </div>
        </div>
    );
};

import SelectableCard from '../../SelectableCard/SelectableCard';
import orderPreparationModalStyles from '../OrderPreparationModal.module.css';
import modalStyles from '../../Modal/Modal.module.css';
import { getOrderTypeName, OrderTypes } from '../../../context/OrderTypes';
import { useVesselOptions } from '../../../hooks/useVesselOptions';
import Button from '../../Button/Button';

interface Props {
    onAction: () => void;
    actionName: string;
    selectedOrderType?: OrderTypes;
    handleSelectOrderType: (selectedOrderType: OrderTypes) => Promise<void>;
    onBack: () => void;
}

const OrderTypeStepContent: React.FC<Props> = ({
    onAction,
    actionName,
    selectedOrderType,
    handleSelectOrderType,
    onBack,
}) => {
    const vesselOptions = useVesselOptions(); 

    return (
        <>
            <div className={orderPreparationModalStyles.contentContainer}>
                <div>
                    {vesselOptions.availableOrderTypes.map(
                        (orderType: OrderTypes) => (
                            orderType !== OrderTypes.season
                            ? <SelectableCard
                                   key={orderType}
                                   title={getOrderTypeName(orderType)}
                                   isSelected={orderType === selectedOrderType}
                                   onClick={() => handleSelectOrderType(orderType)}
                               />
                            : ""
                           )
                    )}
                </div>
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Back" onClick={onBack} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={actionName}
                        onClick={onAction}
                        primary={Boolean(selectedOrderType)}
                        disabled={!selectedOrderType}
                    />
                </div>
            </div>
        </>
    );
};

export default OrderTypeStepContent;

import { DisplayedStatus } from '../../context/utils/displayedStatus';
import styles from './StatusBadge.module.css';

interface StatusBadgeProps {
    backgroundColor?: string;
    color?: string;
    withPadding?: boolean;
}

const StatusBadge: React.FC<React.PropsWithChildren<StatusBadgeProps>> = ({
    children,
    backgroundColor,
    color,
    withPadding,
}) => {

    const isOrange =
        children === DisplayedStatus.awaitingReceival ||
        children === DisplayedStatus.awaitingAction 
 
    return (
       <div
           className={[
                styles.chip,
                isOrange ? styles.orange : styles.gray,
                withPadding ? styles.withPadding : ''].join(' ')}
           style={{ backgroundColor, color }}
    >
        {children}
       </div>
   );
}

export default StatusBadge;

import styles from './SaveDraftModal.module.css';
import Modal from '../Modal/Modal';
import React, { SetStateAction, useState } from 'react';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import Input from '../Input/Input';
import { useEditableOrder } from '../../hooks/useEditableOrder';
import modalStyles from '../Modal/Modal.module.css';
import Button from '../Button/Button';
import { FlexSpacer } from '../FlexSpacer/FlexSpacer';
import { useRouter } from 'next/router';
import { OverviewTabType } from '../OrdersOverviewComponents/OrdersOverviewTabs/OverviewTab';
import { ToastType, useToast } from '../../context/ToastContext';
import { useEditableOrders } from '../../hooks/useEditableOrders';

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
    orderToSaveId: string;
}

export const SaveDraftModal: React.FC<Props> = ({
    orderToSaveId,
    isOpen,
    setIsOpen,
}) => {
    const { data: order, saveDraft } = useEditableOrder(orderToSaveId);
    const { data: editableOrders } = useEditableOrders();
    const [draftName, setDraftName] = useState<string>(
        order?.draftName ?? `${order?.rfq?.deliveryPort?.portName} #DRAFT`,
    );
    const [isSavingDraftInProgress, setIsSavingDraftInProgress] =
        useState(false);
    const [error, setError] = useState<string | null>(null);

    const { push } = useRouter();
    const { showToast } = useToast();

    const changeDraftNameHandler = (value: string) => {
        setDraftName(value);
        setError(null); 
    };

    const onSaveDraft = async () => {
        if (!order) {
            return;
        }

        const draftNameExists = editableOrders?.some(
            (editableOrder) => editableOrder.draftName?.toLowerCase() === draftName?.toLowerCase(),
        );

        if (draftNameExists) {
            setError('This name is already existing in the draft list.');
            return;
        }

        try {
            setIsSavingDraftInProgress(true);
            await new Promise((resolve) => setTimeout(resolve, 1000));
            await saveDraft(order, draftName);
            setTimeout(() => {
                showToast({
                    type: ToastType.success,
                    text: 'Draft saved',
                });
            }, 1000);
        } catch (err) {
            console.log(err);
        }

        setIsSavingDraftInProgress(false);
        setIsOpen(false);
        await push(`/order?tab=${OverviewTabType.drafts}`);
    };

    return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} noHeader>
            <ModalHeader
                subTitle="Name this draft order"
                description="Make it easy to resume your draft with a name that makes sense to you, or use the one we’ve provided."
            ></ModalHeader>
            <div className={styles.container}>
                <h3>Order Name</h3>
                <Input
                    label=""
                    value={draftName}
                    onChangeValue={changeDraftNameHandler}
                    maxLength={150}
                    error={error ?? undefined}
                />
                <p>{draftName.length}/150 characters</p>
            </div>
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Cancel"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                        light
                    />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={
                            error
                                ? 'Unable to Save Draft'
                                : isSavingDraftInProgress
                                ? 'Saving...'
                                : 'Save draft'
                        }
                        onClick={onSaveDraft}
                        primary
                        disabled={!!error || isSavingDraftInProgress}
                    />
                </div>
            </div>
        </Modal>
    );
};

import { OrderTypes } from '../../context/OrderTypes';
import { db } from '../../db/db';
import { getLatestRfq } from '../../db/utils/getLatestRfq';
import { EditableOrder, Order } from '../../types/order.types';
import { isEditableOrder, isOrder } from './typeGuards';

export const getMostRelevantRfq = (ordersList: Order[] | EditableOrder[]) => {
    const sortedOrdersList = [...ordersList];

    const priorities = [
        { type: OrderTypes.provision, priority: 1 },
        {
            type: OrderTypes.bonded,
            priority: 2,
        },
        {
            type: OrderTypes.nonFood,
            priority: 3,
        },
        {
            type: OrderTypes.season,
            priority: 4,
        },
    ];

    const getPriorityForType = (type: OrderTypes) => {
        const priorty = priorities.find((item) => item.type === type);
        return priorty?.priority ?? 0;
    };

    sortedOrdersList.sort(
        (a, b) => getPriorityForType(a.type) - getPriorityForType(b.type),
    );

    const mostRelevantOrder = sortedOrdersList[0];

    if (!mostRelevantOrder) {
        return null;
    }

    if (isEditableOrder(mostRelevantOrder)) {
        return mostRelevantOrder.rfq;
    }

    if (isOrder(mostRelevantOrder)) {
        return getLatestRfq(mostRelevantOrder);
    }
};

export const getOpenOrdersData = async (
    orderTypeSelectedInModal: OrderTypes,
) => {
    const orderTypesToCheck = [
        OrderTypes.provision,
        OrderTypes.bonded,
        OrderTypes.nonFood,
        OrderTypes.season,
    ].filter((item) => item !== orderTypeSelectedInModal);

    let mostRelevantRfq;

    const openEditableOrders = await db.editableOrders
        .where('type')
        .anyOf(orderTypesToCheck)
        .and((item) => item.status < 7)
        .toArray();

    if (openEditableOrders.length > 0) {
        mostRelevantRfq = getMostRelevantRfq(openEditableOrders);
    } else {
        const openAwaitingActionOrders = await db.orders
            .where('type')
            .anyOf(orderTypesToCheck)
            .and((item) => item.status < 7)
            .toArray();

        mostRelevantRfq = getMostRelevantRfq(openAwaitingActionOrders);
    }

    if (mostRelevantRfq) {
        const { deliveryDate, deliveryPort, agent } = mostRelevantRfq;

        return {
            deliveryPort,
            deliveryDate,
            agent,
        };
    }

    return {};
};

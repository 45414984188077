/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateReOpenCommand } from '../models/CreateReOpenCommand';
import type { RfqFromWrist } from '../models/RfqFromWrist';
import type { RfqIdDto } from '../models/RfqIdDto';
import type { UpdateClaimsFromWritsCommand } from '../models/UpdateClaimsFromWritsCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WristItScope {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Reclaim report
     * Requires Integration:UpdateOrder claim
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public createReclaim(
requestBody?: UpdateClaimsFromWritsCommand,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ReClaim',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Reopen report
     * Requires Integration:UpdateOrder claim
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public createReopenReport(
requestBody?: CreateReOpenCommand,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/integration/reopen',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Creates new RFQ entry to an Order
     * Requires Integration:UpdateOrder claim
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public updateRfq(
requestBody?: RfqFromWrist,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/integration/rfq',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Assigns RFQ ID to an Order
     * Requires Integration:UpdateOrder claim
     * @param wbPono 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public assignRfqId(
wbPono?: string,
requestBody?: RfqIdDto,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/integration/rfq/id',
            query: {
                'wbPono': wbPono,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

}
import EventEmitter from 'events';
import { OrderTypes } from '../context/OrderTypes';
import { EditableOrder, Order } from '../types/order.types';
import { ReceivedOrder } from '../types/receivedOrder.types';
import { StocktakingReport } from '../types/stocktaking.types';
import { TypeSafeEvents } from './typeSafeEvent';

class Emitter extends EventEmitter {
    emit(eventName: string | symbol, ...args: any[]) {
        if (process.env.NODE_ENV !== 'production') {
            console.info(
                `[dataFlowEventHub][${eventName.toString()}] Event emitted`,
            );
        }
        return super.emit(eventName, ...args);
    }
}

export interface EditableOrderQuantityChangedPayload {
    itemNumber: string;
    newValue: number;
}

export type DataFlowEvents = {
    preloadData: undefined;
    productsChanged: undefined;
    portsChanged: undefined;
    assortmentChanged: undefined;
    receivedOrdersChanged: undefined;
    receivedOrderChanged: ReceivedOrder['orderId'];
    loadingPrices: boolean;
    orderChanged: Order['orderId'];
    ordersChanged: undefined;
    editableOrdersChanged: undefined;
    editableOrderChanged: {
        orderId?: number;
        localOrderId: string;
        updatedOrder?: EditableOrder;
    };
    offlineOrderChanged: {
        localOrderId: string;
        updatedOrder?: EditableOrder;
    };
    ongoingOrderChanged: OrderTypes; // todo: do we need that anymore?
    offlineOrdersChanged: undefined;
    manningMetadataChanged: undefined;
    stocktakingReportChanged: StocktakingReport['reportMonth'];
    editableOrderQuantityChanged: EditableOrderQuantityChangedPayload;
    newEditableOrderVersion: undefined;
    receivalSubmitted: undefined;
    condemnItemSubmitted: undefined;
    claimItemSubmitted: undefined;
    offlineRequestSubmitted: undefined;
};

export const dataFlowEventHub: TypeSafeEvents<DataFlowEvents> = new Emitter();

export const swrDefaultConfig = {
    revalidateIfStale: false,
    revalidateOnReconnect: false,
};

export enum SWRCacheKeys {
    ongoingOrder = '/local:indexeddb/ongoingOrder',
    searchClaimProducts = 'SearchClaimsProductStep:searchProducts',
    searchReceivalMissingItem = 'MissingItemSearchModal:searchProducts',
    searchProducts = 'SearchResults:searchProducts',
    searchProductsSeasonal = 'SearchResults:searchProductsSeasonal',
    searchProductsAllAssortments = 'SearchResults:searchProductsAllAssortments',
    assortments = '/local:indexeddb/assortments',
    editableOrder = '/local:indexeddb/editableOrder',
    editableOrders = '/local:indexeddb/editableOrders',
    offlineOrder = '/local:indexeddb/offlineOrder',
    offlineOrders = '/local:indexeddb/offlineOrders',
    order = '/local:indexeddb/order',
    orders = '/local:indexeddb/orders',
    ports = '/local:indexeddb/ports',
    products = '/local:indexeddb/products',
    prices = '/local:indexeddb/prices',
    provisionProducts = '/local:indexeddb/provisionProducts',
    receivedOrder = '/local:indexeddb/receivedOrder',
    receivedOrders = '/local:indexeddb/receivedOrders',
    vesselMetadata = '/local:indexeddb/vesselMetadata',
    vesselOptions = '/local:indexeddb/vesselOptions',
    manningMetadata = '/local:indexeddb/manningMetadata',
    manningReports = '/local:indexeddb/manningReports',
    manningReport = '/local:indexeddb/manningReport',
    searchStocktakingProducts = 'SearchResults:stocktakingProducts',
    searchStocktakingProductsAssortment = 'SearchResults:stocktakingProductsAssortment',
    stocktakingReports = '/local:indexeddb/stocktakingReports',
    stocktakingReport = '/local:indexeddb/stocktakingReport',
    initialStocktakingData = '/api/initialStocktakingData',
    reOpenRequests = '/api/reopen',
    featureAnnouncements = '/local:indexeddb/featureAnnouncements',
    pendingCashPurchaseOrder = '/local:indexeddb/pendingCashPurchaseOrder',
    cashPurchaseOrders = '/local:indexeddb/cashPurchaseOrders',
    cashPurchaseOrder = '/local:indexeddb/cashPurchaseOrder',
    claims = '/local:indexeddb/claims',
    pendingItemIssues = '/local:indexeddb/pendingItemIssues',
    externalCurrenciesWithRates = '/local:indexeddb/externalCurrenciesWithRates',
    externalCurrencyWithRate = '/local:indexeddb/externalCurrencyWithRate',
    sendingCashPurchaseOrders = '/local:indexeddb/sendingCashPurchaseOrders',
    sendingCashPurchaseOrder = '/local:indexeddb/sendingCashPurchaseOrder',
    vesselVrRates = '/local:indexeddb/vesselVrRates',
    availableItemsToClaim = '/local:indexeddb/availableItemsToClaim',
    condemnReports = '/local:indexeddb/condemnReports',
    condemnReport = '/local:indexeddb/condemnReport',
    sendingCondemnReports = '/local:indexeddb/sendingCondemnReports',
    claimsInReceival = '/local:indexeddb/claimsInReceival',
    seasonalPeriod = '/local:indexeddb/seasonalPeriod',
}

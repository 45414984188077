import React, { useEffect, useMemo, useState } from 'react';
import SearchProductsBox from '../../SearchProductsBox/SearchProductsBox';
import { List } from '../../List/List';
import { Field } from '../../List/Field';
import moment from 'moment';
import Button from '../../Button/Button';
import modalStyles from '../../Modal/Modal.module.css';
import styles from './SearchProductsWithIssuesStep.module.css';
import claimOrCondemnPreparationModalStyles from '../ClaimOrCondemnPreparationModal.module.css';
import { Product } from '../../../types/order.types';
import { getLastOrderedDates } from '../utils/itemIssueHelpers';
import {
    ItemIssueDetails,
    ItemIssueUIType,
    UpdateItemIssue,
} from '../ClaimOrCondemnPreparationModal';
import { useOrders } from '../../../hooks/useOrders';
import { useProductSearch } from '../../../hooks/useProductSearch';
import useSWR from 'swr';
import { throttle } from 'throttle-debounce';
import { SWRCacheKeys } from '../../../events/dataFlowEvents';
import { useProvisionProducts } from '../../../hooks/useProvisionProducts';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import { Placeholder } from './Placeholder';
import { useSWRCachedResult } from '../../../hooks/useSWRCachedResult';
import { useStocktakingReports } from '../../../hooks/useStocktakingReports';
import { useItemsAvailableToClaim } from '../../../hooks/useItemsAvailableToClaim';
import { getProductsAvailableToClaimToDisplayInSearchList } from '../../../db/itemsAvailableToClaim';

interface SearchClaimsProductsStepProps {
    onContinue: () => void;
    updateItemIssue: UpdateItemIssue;
    itemIssueDetails: ItemIssueDetails;
}

const SearchProductsWithIssuesStep: React.FC<SearchClaimsProductsStepProps> = ({
    onContinue,
    updateItemIssue,
    itemIssueDetails,
}) => {
    const isCondemn =
        itemIssueDetails.itemIssueType === ItemIssueUIType.Condemn;

    const {
        searchProductInputValue,
        selectedProduct: { itemNumber: selectedProductItemNumber },
    } = itemIssueDetails;

    const [searchValue, setSearchValue] = useState(searchProductInputValue);

    const { data: orders } = useOrders();

    const allCompletedOrders =
        orders?.filter((order) => order.status === 7) ?? [];

    const { lastOrderedLineItems } = useMemo(
        () => getLastOrderedDates(allCompletedOrders),
        [allCompletedOrders],
    );

    const { data: products } = useProvisionProducts();
    const { stocktakingReports } = useStocktakingReports();
    const lastStocktakingReportItems =
        stocktakingReports && stocktakingReports[0]?.items;
    const { itemsAvailableToClaim } = useItemsAvailableToClaim();
    const productsToSearchThroughClaim =
        getProductsAvailableToClaimToDisplayInSearchList(
            itemsAvailableToClaim ?? [],
            products,
        );

    const selectProduct = (product: Product) => {
        updateItemIssue('selectedProduct', {
            itemNumber: product.itemNumber,
            name: product.itemName,
        });
        updateItemIssue('searchProductInputValue', searchValue);
        if (product.itemNumber === selectedProductItemNumber) {
            updateItemIssue('selectedProduct', {
                itemNumber: null,
                name: null,
            });
        }
    };

    const { windowWidth } = useWindowWidth();

    const productsToSearchThroughCondemn = products.flatMap((product) => {
        const stocktakeQuantity = lastStocktakingReportItems?.find(
            (lastStocktakingItem) =>
                lastStocktakingItem.itemNumber === product.itemNumber,
        )?.quantity;
        return { ...product, orderedQuantity: stocktakeQuantity };
    });

    const { search } = useProductSearch(
        isCondemn
            ? productsToSearchThroughCondemn
            : productsToSearchThroughClaim,
    );

    const {
        data,
        isValidating: isSearching,
        mutate: refreshSearch,
    } = useSWR(
        searchValue ? [SWRCacheKeys.searchClaimProducts, searchValue] : null,
        ([, searchValue]) => search(searchValue),
    );

    useEffect(() => {
        refreshSearch();
    }, []);

    const dataToDisplay = useSWRCachedResult(data);

    const throttleSetSearchValue = throttle(
        500,
        (value: string) => setSearchValue(value),
        { noLeading: true },
    );

    const displayedData = useMemo(
        () => (
            <div className={styles.listContainer}>
                <List<Product>
                    data={dataToDisplay}
                    idSource="itemNumber"
                    onRowClick={selectProduct}
                    getIsRowActive={(record) =>
                        record.itemNumber === selectedProductItemNumber
                    }
                    applySelectableRowStyle
                    emptyState={
                        <Placeholder
                            title="No results"
                            subtitle="Please make sure that you’ve spelled the product
                        correctly."
                        />
                    }
                >
                    <Field<Product>
                        source="itemName"
                        title="Name"
                        bold
                        smallFont
                        customWidth="35%"
                        truncate
                    />
                    <Field<Product>
                        source="itemNumber"
                        title="Item number"
                        bold
                        smallFont
                        customWidth="35%"
                        truncate
                    />
                    {windowWidth > 650 ? (
                        <Field<Product>
                            source=""
                            title="Product category"
                            grey
                            smallFont
                            customWidth="25%"
                            renderFunction={({}, productToDisplay) => (
                                <>{productToDisplay?.categoryLevel3.text}</>
                            )}
                        />
                    ) : null}
                    {isCondemn ? (
                        <Field<Product>
                            source=""
                            renderFunction={({}, productToDisplay) => {
                                const stocktakingItem =
                                    lastStocktakingReportItems &&
                                    lastStocktakingReportItems.find(
                                        (item) =>
                                            item.itemNumber ===
                                            productToDisplay?.itemNumber,
                                    );

                                const unitOfMeasure =
                                    stocktakingItem?.unitOfMeasure ??
                                    productToDisplay?.unitOfMeasure ??
                                    '';
                                return (
                                    <div className={styles.centerText}>
                                        {`${stocktakingItem?.quantity ?? 0
                                        } ${unitOfMeasure}`}
                                    </div>
                                );
                            }}
                            customWidth="25%"
                            title={
                                <div className={styles.centerText}>
                                    Stock quantity
                                </div>
                            }
                            grey
                            smallFont
                        />
                    ) : null}
                    {windowWidth > 800 && !isCondemn ? (
                        <Field<Product>
                            source=""
                            renderFunction={({}, productToDisplay) => {
                                const itemAvailableToClaim =
                                    itemsAvailableToClaim!.find(
                                        (item) =>
                                            item.itemNumber ===
                                            productToDisplay?.itemNumber,
                                    );
                                return (
                                    <>
                                        {itemAvailableToClaim?.lastOrdered
                                            ? moment(
                                                  itemAvailableToClaim.lastOrdered,
                                              ).format('DD.MM.YYYY')
                                            : '-'}
                                    </>
                                );
                            }}
                            customWidth="25%"
                            title="Last ordered"
                            grey
                            smallFont
                        />
                    ) : null}
                </List>
                <div
                    className={`${
                        selectedProductItemNumber
                            ? styles.bottomSpacer
                            : styles.smallBottomSpacer
                    }`}
                ></div>
            </div>
        ),
        [
            dataToDisplay,
            lastOrderedLineItems,
            selectedProductItemNumber,
            isSearching,
        ],
    );

    return (
        <div data-testid="searchClaimsProductsStep">
            <div
                className={claimOrCondemnPreparationModalStyles.headerContainer}
            >
                <p>{isCondemn ? 'Condemn' : 'Claim'}</p>
                <h1>
                    {isCondemn
                        ? 'What item/s do you wish to condemn?'
                        : 'What item/s did you find an issue with?'}
                </h1>
            </div>

            {searchValue.length === 0 ? (
                <Placeholder
                    subtitle="Begin typing to find the product you wish to add."
                    title="Search for a product"
                />
            ) : (
                displayedData
            )}

            <SearchProductsBox
                initialValue={searchProductInputValue}
                onChange={throttleSetSearchValue}
                isProductSelected={Boolean(selectedProductItemNumber)}
                isLoading={isSearching}
            />

            {selectedProductItemNumber && (
                <div
                    className={`${styles.button} ${modalStyles.squareActionButton}`}
                >
                    <Button text="Continue" onClick={onContinue} primary />
                </div>
            )}
        </div>
    );
};

export default SearchProductsWithIssuesStep;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateReOpenCommand } from '../models/CreateReOpenCommand';
import type { ItemIssueStatus } from '../models/ItemIssueStatus';
import type { OrderStatus } from '../models/OrderStatus';
import type { UpdateClaimsFromWritsCommand } from '../models/UpdateClaimsFromWritsCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FakerScope {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Delete existing manning report by id
     * Requires user privileges. Does not work in production
     * @param manningReportId 
     * @returns any Success
     * @throws ApiError
     */
    public deleteManningReport(
manningReportId: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/integration/fake/manning/{manningReportId}',
            path: {
                'manningReportId': manningReportId,
            },
        });
    }

    /**
     * Set cash purchase origin to AX and send notification
     * Requires user privileges. Does not work in production
     * @param cashPurchaseId 
     * @returns any Success
     * @throws ApiError
     */
    public updateCashPurchase(
cashPurchaseId: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/integration/fake/cash-purchase/{cashPurchaseId}',
            path: {
                'cashPurchaseId': cashPurchaseId,
            },
        });
    }

    /**
     * Changes status of an Item issue
     * Requires user privileges. Does not work in production
     * @param itemIssueId 
     * @param status
 *
 * 0 = Created
 *
 * 1 = SentToGarrets
 *
 * 2 = Completed
 *
 * 3 = Rejected
     * @returns any Success
     * @throws ApiError
     */
    public changeItemIssueStatus(
itemIssueId?: number,
status?: ItemIssueStatus,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/FakeItemIssue',
            query: {
                'itemIssueId': itemIssueId,
                'status': status,
            },
        });
    }

    /**
     * Imitates Reclaim request from Garrets VAM
     * Requires user privileges. Does not work in production
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public createReclaim(
requestBody?: UpdateClaimsFromWritsCommand,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/FakeReClaim',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Imitates Reopen request from Garrets VAM
     * Requires user privileges. Does not work in production
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public createReopen(
requestBody?: CreateReOpenCommand,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/integration/fake/reopen',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Imitates RFQ response from Garrets VAM
     * Requires user privileges. Does not work in production
     * @param orderId 
     * @param lineItemsToUpdate 
     * @param lineItemsToAdd 
     * @param lineItemsToRemove 
     * @param idOfItemOutsideAssortments 
     * @param attachComments 
     * @param removeSspoNumber 
     * @returns any Success
     * @throws ApiError
     */
    public createFakeRfq(
orderId?: number,
lineItemsToUpdate?: number,
lineItemsToAdd?: number,
lineItemsToRemove?: number,
idOfItemOutsideAssortments?: string,
attachComments?: boolean,
removeSspoNumber?: boolean,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/integration/fake/rfq',
            query: {
                'OrderId': orderId,
                'LineItemsToUpdate': lineItemsToUpdate,
                'LineItemsToAdd': lineItemsToAdd,
                'LineItemsToRemove': lineItemsToRemove,
                'IdOfItemOutsideAssortments': idOfItemOutsideAssortments,
                'AttachComments': attachComments,
                'RemoveSspoNumber': removeSspoNumber,
            },
        });
    }

    /**
     * Changes status of an RFQ
     * Requires user privileges. Does not work in production
     * @param orderId 
     * @param status
 *
 * 0 = Blank
 *
 * 1 = Created
 *
 * 2 = RfqSent
 *
 * 3 = OrderForReview
 *
 * 4 = FinalOrder
 *
 * 5 = OrderForConfirmation
 *
 * 6 = ReceiptInProgress
 *
 * 7 = Receipt
 *
 * 8 = CancelledByVessel
 *
 * 9 = CancelledByGarrets
     * @returns any Success
     * @throws ApiError
     */
    public changeOrderStatus(
orderId?: number,
status?: OrderStatus,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/integration/fake/rfq/change-status',
            query: {
                'orderId': orderId,
                'status': status,
            },
        });
    }

    /**
     * Simulates AX ending order processs. It changes the order status and adds a new RFQ.
     * Requires user privileges. Does not work in production
     * @param orderId 
     * @returns any Success
     * @throws ApiError
     */
    public endOrder(
orderId?: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/integration/fake/rfq/end',
            query: {
                'orderId': orderId,
            },
        });
    }

    /**
     * Sends email to email address from query parameter
     * Requires user privileges
     * @param email 
     * @returns any Success
     * @throws ApiError
     */
    public sendEmail(
email: string,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/integration/fake/rfq/send-email',
            query: {
                'email': email,
            },
        });
    }

    /**
     * Delete existing stocktaking report by id
     * Requires user privileges. Does not work in production
     * @param stocktakingReportId 
     * @returns any Success
     * @throws ApiError
     */
    public deleteStocktakingReport(
stocktakingReportId: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/integration/fake/stocktaking/{stocktakingReportId}',
            path: {
                'stocktakingReportId': stocktakingReportId,
            },
        });
    }

}
import {
    OrderToDisplay,
    OrderToDisplayInOrdersList,
} from '../../types/order.types';
import React from 'react';
import { OrderStatus } from '../../apiClient/generated';
import styles from './OrdersOverview.module.css';
import Provision from '../../public/gfx/menuIcons/provisionBold.svg';
import Nonfood from '../../public/gfx/menuIcons/nonfoodBold.svg';
import Bonded from '../../public/gfx/menuIcons/bondedBold.svg';
import Seasonal from '../../public/gfx/menuIcons//seasonalBold.svg';
import Draft from '../../public/gfx/menuIcons/draftIcon.svg';
import CashPurchase from '../../public/gfx/menuIcons/cashPurchaseIcon.svg';
import { OrderTypes } from '../../context/OrderTypes';

interface Props {
    orderToDisplay: OrderToDisplay | OrderToDisplayInOrdersList;
}

export const OrderIcon: React.FC<Props> = ({ orderToDisplay }) => {
    const isOffline = orderToDisplay.isSending;

    let stroke:string
    let iconAttributes:string
    switch (true) { 
        case (
            orderToDisplay.status === OrderStatus.ReceiptInProgress ||
            orderToDisplay.status === OrderStatus.OrderForConfirmation)
            && orderToDisplay.type === OrderTypes.provision
            && !isOffline: 
        case orderToDisplay.status === OrderStatus.OrderForReview && !isOffline:
            stroke = '#FF902A'
            iconAttributes = `${styles.icon} ${styles.iconOrange}`
            break
        default:
            stroke = '#7a828d'
            iconAttributes = `${styles.icon}`
            break
    }

    const getIconToDisplay = () => {
        if (
            orderToDisplay.draftName &&
            orderToDisplay.status === OrderStatus.Blank &&
            !orderToDisplay.isSending
        ) {
            return <Draft width="20" height="20" stroke={stroke} />;
        } else if (orderToDisplay?.type === OrderTypes.nonFood) {
            return <Nonfood width="17" height="17" stroke={stroke} />;
        } else if (orderToDisplay?.type === OrderTypes.bonded) {
            return <Bonded width="15" height="18" stroke={stroke} />;
        } else if (orderToDisplay?.type === OrderTypes.provision) {
            return <Provision width="17" height="19" stroke={stroke} />;
        } else if (orderToDisplay?.type === OrderTypes.season) {
            return <Seasonal width="17" height="19" stroke={stroke} />;
        } else if (orderToDisplay.type === OrderTypes.cash) {
            return <CashPurchase width="17" height="18" stroke={stroke} />;
        }
    };

    return <div className={iconAttributes}>{getIconToDisplay()}</div>;
};

import OrderSummaryTabs from '../OrderSummaryTabs/OrderSummaryTabs';
import OrderSummaryRemarksTab from '../OrderSummaryRemarksTab/OrderSummaryRemarksTab';
import { OrderSummaryProductsTab } from '../OrderSummaryProductsTab/OrderSummaryProductsTab';
import OrderSummaryDetailsTab from '../OrderSummaryDetailsTab/OrderSummaryDetailsTab';
import styles from '../../styles/pages/Order.module.css';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction } from 'react';
import { OrderToDisplay, Product } from '../../types/order.types';
import { ValidationFields } from '../../hooks/useOrderValidation';
import { Tab } from '../../pages/order/[orderType]/[orderId]/summary/[orderSummaryTab]';
import { RFQChangelog } from '../../hooks/useChangelog';

interface OrderSummaryProps {
    activeTab: Tab;
    productsListIncludingItemsRemovedDuringRfqFlow: Product[];
    setActiveTab: Dispatch<SetStateAction<Tab>>;
    validationErrors: ValidationFields;
    setValidationErrors: Dispatch<SetStateAction<ValidationFields>>;
    changelog: RFQChangelog;
    orderToDisplay: OrderToDisplay;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
    orderToDisplay,
    activeTab,
    changelog,
    productsListIncludingItemsRemovedDuringRfqFlow,
    setActiveTab,
    validationErrors,
    setValidationErrors,
}) => {
    const { query, push } = useRouter();

    return (
        <section className={styles.contentContainer}> 
            <OrderSummaryTabs
                activeTab={activeTab}
                validationErrors={validationErrors}
                setActiveTab={(tab) => {
                    if (Object.values(Tab).includes(tab as Tab)) {
                        setActiveTab(tab as Tab);
                    } else {
                        setActiveTab(Tab.details);
                    }

                    // Update tab in current browser URL
                    push(
                        `/order/${query?.orderType}/${
                            query?.orderId
                        }/summary/${encodeURIComponent(tab)}`,
                        undefined,
                        { shallow: true },
                    );
                }}
                // By using renderprops we can pass data directly from here to the tab content components
                detailsTabContent={
                    <OrderSummaryDetailsTab
                        orderToDisplay={orderToDisplay}
                        validationErrors={validationErrors}
                        setValidationErrors={setValidationErrors}
                    />
                }
                productsTabContent={
                    <OrderSummaryProductsTab
                        orderToDisplay={orderToDisplay}
                        changelog={changelog}
                        products={
                            productsListIncludingItemsRemovedDuringRfqFlow
                        }
                    />
                }
                remarksTabContent={
                    <OrderSummaryRemarksTab orderToDisplay={orderToDisplay} />
                }
            />
        </section>
    );
};

export default OrderSummary;

import {
    OrderToDisplay,
    OrderToDisplayInOrdersList,
} from '../../types/order.types';
import { Tab } from '../../pages/order/[orderType]/[orderId]/summary/[orderSummaryTab]';
import {
    PerformanceReportStep,
    ReceivalStep,
} from '../../pages/order/[orderType]/[orderId]/receival/[receivalStep]';
import { OrderStatus } from '../../apiClient/generated';
import { OrderTypes } from '../../context/OrderTypes';

const parseOrderMetadata = (
    orderToDisplay: OrderToDisplay | OrderToDisplayInOrdersList,
) => {
    const { localOrderId, orderId, type } = orderToDisplay;
    return {
        localOrderId,
        orderId: orderId?.toString(),
        type: type.toLowerCase(),
    };
};

export const getOrderPath = (
    orderToDisplay: OrderToDisplay | OrderToDisplayInOrdersList,
    targetScreen: 'preparation' | 'summary' = 'summary',
) => {
    const { localOrderId, orderId, type } = parseOrderMetadata(orderToDisplay);

    if (orderToDisplay.type === OrderTypes.cash && !orderToDisplay.isSending) {
        return `/order/cash/${orderId}/details`;
    }

    if (orderToDisplay.type === OrderTypes.cash && orderToDisplay.isSending) {
        return `/order/cash/${localOrderId}/details`;
    }

    if (orderToDisplay.status === OrderStatus.Blank && !orderToDisplay.isSending) {
        return `/order/${type}/${localOrderId}/preparation`;
    }
     
    const getRelevantOrderId = () => {
        if (orderToDisplay.status === OrderStatus.OrderForReview) {
            return localOrderId ?? orderId;
        }
        return orderId ?? localOrderId;
    };

    return `/order/${type}/${getRelevantOrderId()}/summary/${Tab.details}`;
};

export const getOrderReceivalPath = (
    orderToDisplay: OrderToDisplay,
    step: ReceivalStep | PerformanceReportStep,
) => {
    const { type, orderId } = orderToDisplay;

    if (!orderId) {
        return getOrderPath(orderToDisplay);
    }

    return `/order/${type.toLowerCase()}/${orderId}/receival/${step}`;
};

import React, { createContext, useContext } from 'react';
import { apiClient } from '../apiClient/apiClient';
import useSWR, { KeyedMutator } from 'swr';
import {
    getNotificationsFromDb,
    putNotificationsToDB,
} from '../db/notifications';
import {
    CashPurchaseNotificationStatus,
    NotificationSource,
    OrderStatus,
    ReOpenSource,
} from '../apiClient/generated';

export interface ReopenNotification {
    source: NotificationSource.Reopen;
    notificationId: number;
    created: string;
    reOpenSource: ReOpenSource;
    reportMonth: string;
}

export interface OrderNotification {
    source: NotificationSource.Order;
    notificationId: number;
    notificationType: OrderStatus;
    orderId: number;
    created: string;
    type: string;
    wbPono: string;
}

export interface CashPurchaseNotification {
    source: NotificationSource.CashPurchase;
    notificationId: number;
    notificationType: OrderStatus;
    cashPurchaseId: number;
    cashPurchaseStatus: CashPurchaseNotificationStatus;
    created: string;
    type: string;
    wbPono: string;
}

export interface ClaimNotification {
    source: NotificationSource.ItemIssue;
    notificationId: number;
    created: string;
    wbPono: string;
    itemIssueId: number;
}

export type NotificationToDisplay =
    | ReopenNotification
    | OrderNotification
    | CashPurchaseNotification
    | ClaimNotification;

interface NotificationsContextValues {
    putNotifications: () => void;
    getNotificationsFromDB: () => void;
    notifications: {
        data?: NotificationToDisplay[];
        refreshNotifications?: KeyedMutator<NotificationToDisplay[]>;
    };
}

type NotificationsProviderProps = {
    children: React.ReactNode;
};

const NotificationsContext = createContext<
    NotificationsContextValues | undefined
>(undefined);

const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
    const getNotificationsFromDB = () => {
        return getNotificationsFromDb();
    };

    const isValidNotification = (
        notification: unknown,
    ): notification is NotificationToDisplay => {
        if (
            typeof (notification as NotificationToDisplay).source ===
            'undefined'
        ) {
            return false;
        }

        if (
            (notification as NotificationToDisplay).source ===
            NotificationSource.Order
        ) {
            return Boolean(
                (notification as OrderNotification).notificationId &&
                    (notification as OrderNotification).notificationType &&
                    (notification as OrderNotification).orderId &&
                    (notification as OrderNotification).created &&
                    (notification as OrderNotification).type &&
                    (notification as OrderNotification).wbPono,
            );
        }

        if (
            (notification as NotificationToDisplay).source ===
            NotificationSource.Reopen
        ) {
            return Boolean(
                (notification as ReopenNotification).notificationId &&
                    (notification as ReopenNotification).created &&
                    typeof (notification as ReopenNotification).reOpenSource !==
                        'undefined' &&
                    (notification as ReopenNotification).reportMonth,
            );
        }

        if (
            (notification as NotificationToDisplay).source ===
            NotificationSource.CashPurchase
        ) {
            return Boolean(
                (notification as CashPurchaseNotification).notificationId &&
                    (notification as CashPurchaseNotification).cashPurchaseId &&
                    (notification as CashPurchaseNotification)
                        .cashPurchaseStatus &&
                    (notification as CashPurchaseNotification).created &&
                    (notification as CashPurchaseNotification).wbPono,
            );
        }

        if (
            (notification as NotificationToDisplay).source ===
            NotificationSource.ItemIssue
        ) {
            return Boolean(
                (notification as ClaimNotification).notificationId &&
                    (notification as ClaimNotification).itemIssueId &&
                    (notification as ClaimNotification).created &&
                    (notification as ClaimNotification).wbPono,
            );
        }

        console.error(
            new Error(
                `Notification with id:${
                    (notification as NotificationToDisplay).notificationId
                } has incorrect format.`,
            ),
        );

        return false;
    };

    const { data: notifications, mutate } = useSWR(
        'notifications',
        async () => {
            try {
                const response = await apiClient.getNotificationsV2();

                const sortedNotifications: NotificationToDisplay[] = response
                    .flatMap((item) => {
                        if (isValidNotification(item)) {
                            return item;
                        }
                        return [];
                    })
                    .sort(
                        (objA, objB) =>
                            new Date(objB.created).getTime() -
                            new Date(objA.created).getTime(),
                    );

                await putNotificationsToDB(sortedNotifications);
                return sortedNotifications;
            } catch {
                return getNotificationsFromDB();
            }
        },
        {
            revalidateOnFocus: true,
            revalidateIfStale: true,
        },
    );

    const putNotifications = async () => {
        if (notifications) {
            await putNotificationsToDB(notifications);
        }
    };

    return (
        <NotificationsContext.Provider
            value={{
                putNotifications,
                getNotificationsFromDB,
                notifications: {
                    data: notifications,
                    refreshNotifications: mutate,
                },
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};

export { NotificationsProvider };

export const useNotifications = () => {
    const context = useContext(NotificationsContext);
    if (context === undefined) {
        throw new Error('useAbortController is out of scope');
    }

    return context;
};

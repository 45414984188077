import { LineItem, OrderToDisplay, Product } from '../../types/order.types';
import styles from './ProductsList.module.css';
import { adjustLineItemValueToPackSizeAndUnit } from '../utils/lineItemUtils';
import { useRouter } from 'next/router';
import DecimalInput from '../DecimalInput/DecimalInput';
import PricePerPack from './PricePerPack';
import { currencyFormatter } from '../utils/numberFormat';
import {
    getEditableOrderById,
    updateLineItemQuantity,
} from '../../db/editableOrders';
import { useAppLoading } from '../../context/AppLoadingContext';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import { useProductDetails } from '../../context/ProductDetailsContext';
import { useEditableOrder } from '../../hooks/useEditableOrder';
import CommentButton from '../CommentButton/CommentButton';
import React, { useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';
import {
    EditableOrderQuantityChangedPayload,
    dataFlowEventHub,
} from '../../events/dataFlowEvents';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';

interface SingleProductRowProps {
    product: Product;
    orderToDisplay: OrderToDisplay;
    lineItem?: LineItem;
    compact?: boolean;
    status?: ProductRowChangelogStatus;
}

export enum ProductRowChangelogStatus {
    removed = 'removed',
    locked = 'locked',
    updated = 'updated',
    default = 'default',
    unavailable = 'unavailable',
}

const SingleProductRow: React.FC<SingleProductRowProps> = ({
    product,
    lineItem,
    compact,
    status = ProductRowChangelogStatus.default,
    orderToDisplay,
}) => {
    const productRowDetails = {
        itemName: product.itemName || lineItem?.itemName,
        itemNumber: product.itemNumber || lineItem?.itemNumber,
        isEstimatedPrice: !lineItem?.confirmedPrice,
        price:
            lineItem?.confirmedPrice ??
            lineItem?.estimatedPrice ??
            product.estimatedPrice,
        packSize:
            lineItem?.confirmedPackSize ??
            lineItem?.estimatedPackSize ??
            product.estimatedPackSize,
        unitOfMeasure: lineItem?.unitOfMeasure ?? product.unitOfMeasure,
    };

    const {
        itemName,
        itemNumber,
        isEstimatedPrice,
        price,
        packSize,
        unitOfMeasure,
    } = productRowDetails;

    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const { pathname } = useRouter();
    const { loadingPrices } = useAppLoading();
    const productDetails = useProductDetails();
    const { data: editableOrder } = useEditableOrder(
        orderToDisplay.localOrderId,
    );

    const shouldRemoveZeroQuantityProduct = !pathname.includes('/summary');

    const itemQuantityFromDb =
        orderToDisplay.rfq.lineItems.find(
            (item) => item.itemNumber === itemNumber,
        )?.quantity ?? 0;

    const [lineItemAmount, setLineItemAmount] =
        useState<number>(itemQuantityFromDb);

    useEffect(() => {
        const updateQuantity = (event: EditableOrderQuantityChangedPayload) => {
            if (event.itemNumber === itemNumber) {
                setLineItemAmount(event.newValue);
            }
        };
        const updateQuantityFromDb = async () => {
            const editableOrder = await getEditableOrderById(
                orderToDisplay.localOrderId,
            );

            if (editableOrder) {
                const dbQuantity =
                    editableOrder.rfq.lineItems.find(
                        (item) => item.itemNumber === itemNumber,
                    )?.quantity ?? 0;
                setLineItemAmount(dbQuantity);
            }
        };
        dataFlowEventHub.on('editableOrderQuantityChanged', updateQuantity);
        dataFlowEventHub.on('newEditableOrderVersion', updateQuantityFromDb);

        return () => {
            dataFlowEventHub.off(
                'editableOrderQuantityChanged',
                updateQuantity,
            );
            dataFlowEventHub.off(
                'newEditableOrderVersion',
                updateQuantityFromDb,
            );
        };
    }, [orderToDisplay.localOrderId]);

    const saveProductAmount = debounce(500, async (value: number | string) => {
        if (editableOrder) {
            await updateLineItemQuantity(
                editableOrder,
                product,
                Number(value),
                shouldRemoveZeroQuantityProduct,
            );
        }
    });

    const openCommentModal = () => {
        productDetails.openModal(orderToDisplay, `${itemNumber}`, true);
    };

    const getTotalPrice = () => {
        if (!itemQuantityFromDb) {
            return <></>;
        }

        const totalPrice = price * itemQuantityFromDb;

        if (totalPrice === 0) {
            return <></>;
        }

        return (
            <>{`${isEstimatedPrice ? '≈' : ''} ${currencyFormatter(
                totalPrice,
                vesselCurrency,
            )}`}</>
        );
    };

    const getClassNameForChangelogStatus = () => {
        if (status === ProductRowChangelogStatus.unavailable) {
            return styles.unavailable;
        }
        if (
            status === ProductRowChangelogStatus.updated ||
            status === ProductRowChangelogStatus.removed
        ) {
            return styles.highlighted;
        }

        return '';
    };

    const handleOnProductNameClick = () => {
        if (status !== ProductRowChangelogStatus.locked) {
            productDetails.openModal(orderToDisplay, `${itemNumber}`, false);
        }
    };

    const locked = status === ProductRowChangelogStatus.locked;

    return (
        <div
            className={`${styles.singleProductRow} ${
                compact ? styles.compact : ''
            } ${getClassNameForChangelogStatus()}`}
        >
            <div
                className={[styles.tableCell, styles.singleProductCell].join(
                    ' ',
                )}
            ></div>
            <div
                className={[
                    styles.tableCell,
                    styles.singleProductCell,
                    locked ? '' : 'cursorPointer',
                ].join(' ')}
                onClick={handleOnProductNameClick}
            >
                <p className={styles.primary}>{itemName}</p>
                <p className={styles.secondary}>{itemNumber}</p>
            </div>
            <div
                className={[styles.tableCell, styles.singleProductCell].join(
                    ' ',
                )}
            >
                <p className={styles.primary}>{packSize || ''}</p>
                <p className={styles.secondary}>
                    {packSize ? `${unitOfMeasure} / Pack` : ''}
                </p>
            </div>
            <div
                className={[styles.tableCell, styles.singleProductCell].join(
                    ' ',
                )}
            >
                <PricePerPack
                    unitOfMeasure={unitOfMeasure}
                    price={price}
                    isEstimatedPrice={isEstimatedPrice}
                    currency={vesselCurrency}
                />
            </div>
            <div
                className={[
                    styles.tableCell,
                    styles.singleProductCell,
                    styles.directionRow,
                ].join(' ')}
            >
                {locked ? (
                    <p data-testid="singleProductRowTotalPrice">
                        {itemQuantityFromDb}
                    </p>
                ) : (
                    <DecimalInput
                        key={orderToDisplay.localOrderId} // key prop is added here to force re-render when localOrderId has changed.
                        value={lineItemAmount}
                        onChange={(v) => saveProductAmount(v)}
                        onBlurValueConverter={(value) =>
                            adjustLineItemValueToPackSizeAndUnit(
                                Number(value),
                                packSize,
                                unitOfMeasure,
                            )
                        }
                    />
                )}
                <p className={styles.secondary}>&nbsp;{unitOfMeasure ?? ''}</p>
            </div>
            <div
                className={[styles.tableCell, styles.singleProductCell].join(
                    ' ',
                )}
            >
                <p className={styles.primary}>
                    {loadingPrices ? (
                        <SkeletonLoader loaderWidth={70} />
                    ) : (
                        getTotalPrice()
                    )}
                </p>
            </div>
            <div
                className={[styles.tableCell, styles.singleProductCell].join(
                    ' ',
                )}
            >
                <CommentButton
                    onClick={openCommentModal}
                    isActive={!locked}
                    hasComment={Boolean(lineItem?.comment)}
                    status={status}
                />
            </div>
        </div>
    );
};

export default SingleProductRow;

import styles from './SummaryRowSeasonalPeriods.module.css';
import { ReactElement } from 'react';

export const SummaryRowSeasonalPeriods = ({
    fromDate,
    toDate,
    period, 
    value,
    backgroundColor,
    strongBottomBorder,
    labelHelper,
    valueHelper,
}: {
    fromDate?: string; 
    toDate?: string; 
    period?: string; 
    labelHelper?: string;
    valueHelper?: string | ReactElement;
    value: string;
    backgroundColor?: 'grey';
    strongBottomBorder?: boolean;
}) => {
    const getBackgroundColor = () => {
        switch (backgroundColor) {
            case 'grey':
                return '#F4F5F6';
            default:
                return 'transparent';
        }
    };

    return (
        <div
            style={{
                backgroundColor: getBackgroundColor(),
                borderBottom: strongBottomBorder ? '2px solid black' : '',
            }}
            className={[styles.container, styles.containerRow].join(' ')}
        > 
            <div className={styles.textContainer}> 
                <div className={styles.textContainerPeriod}>
                    <p className={styles.label}>{period}
                    </p>
                    {labelHelper && <p className={styles.helper}>{labelHelper}</p>}
                </div>

                {   
                    fromDate && (
                    <div className={styles.textContainerDate}>
                        <p className={styles.date}>{fromDate} to</p> 
                        <p className={styles.date}>{toDate}</p> 
                    </div>
                    )
                }
            </div>

            <div className={styles.value}>
                <p>{value}</p>
                {valueHelper && <p className={styles.helper}>{valueHelper}</p>}
            </div>
        </div>
    );
};

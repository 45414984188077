import moment, { isDate } from 'moment';
import { useState } from 'react';
import { RfqFormFields } from '../components/OrderSummaryDetailsTab/OrderSummaryDetailsTab';
import { RFQ } from '../types/order.types';
import { OrderTypes } from '../context/OrderTypes';
import { Tab } from '../pages/order/[orderType]/[orderId]/summary/[orderSummaryTab]';
import useOrderType from './useOrderType';

const validationErrorFieldMap: Record<keyof ValidationFields, RfqFormFields> = {
    deliveryPortIsEmpty: RfqFormFields.deliveryPort,
    deliveryPortDoesntMatch: RfqFormFields.deliveryPort,
    deliveryDateIsEmpty: RfqFormFields.deliveryDate,
    deliveryDateIsTooEarly: RfqFormFields.deliveryDate,
    agentEmailIsInvalid: RfqFormFields.agentEmail,
    coveringDaysIsNull: RfqFormFields.coveringDays,
    manningIsNull: RfqFormFields.manning,
    departureDateIsTooEarly: RfqFormFields.departureDate,
    departureDateIsEarlierThanDeliveryDate: RfqFormFields.departureDate,
    noLineItems: RfqFormFields.lineItems,
};

const initialValidationErrors = {
    deliveryPortIsEmpty: false,
    deliveryDateIsEmpty: false,
    deliveryDateIsTooEarly: false,
    departureDateIsEarlierThanDeliveryDate: false,
    agentEmailIsInvalid: false,
    manningIsNull: false,
    coveringDaysIsNull: false,
    departureDateIsTooEarly: false,
    deliveryPortDoesntMatch: false,
    noLineItems: false,
};

export type ValidationFields = typeof initialValidationErrors;

export const useOrderValidation = () => {
    const [validationErrors, setValidationErrors] = useState<ValidationFields>(
        initialValidationErrors,
    );

    const { activeOrderType } = useOrderType();
    const isProvision = activeOrderType === OrderTypes.provision;

    const validate = (rfq?: RFQ | null): { tabWithError: Tab | null } => {
        if (!rfq) {
            return {
                tabWithError: Tab.details,
            };
        }
    
        const noLineItems = rfq.lineItems.every((item) => item.quantity === 0);
    
        if (noLineItems) {
            return {
                tabWithError: Tab.products,
            };
        }
    
        const deliveryPortIsEmpty = !rfq.deliveryPort?.portCode;
        const manningIsNull = isProvision && (rfq.manning ?? 0) <= 0;
        const coveringDaysIsNull = isProvision && (rfq.coveringDays ?? 0) <= 0;
    
        const deliveryDateIsEmpty =
            !rfq.deliveryDate || !isDate(moment(rfq.deliveryDate).toDate());
        const deliveryDateIsTooEarly =
            (!rfq.deliveryDate || isDate(moment(rfq.deliveryDate).toDate())) &&
            moment(moment(rfq.deliveryDate).toDate()).isBefore(
                moment().startOf('day'),
            );
    
        const agentEmailIsInvalid =
            rfq.agent?.email !== '' &&
            typeof rfq.agent?.email !== 'undefined' &&
            !(
                rfq.agent?.email?.length &&
                rfq.agent.email.length >= 3 &&
                rfq.agent.email.includes('@') &&
                rfq.agent.email[0] !== '@' &&
                rfq.agent.email[rfq.agent.email.length - 1] !== '@'
            );
    
        let departureDateIsTooEarly = false;
        let departureDateIsEarlierThanDeliveryDate = false;
    
        if (rfq.departureDate) {
            departureDateIsTooEarly =
                isDate(moment(rfq.departureDate).toDate()) &&
                moment(moment(rfq.departureDate).toDate()).isBefore(
                    moment().startOf('day'),
                );
    
            departureDateIsEarlierThanDeliveryDate =
                isDate(moment(rfq.departureDate).toDate()) &&
                moment(moment(rfq.departureDate).startOf('day').toDate()).isBefore(
                    moment(rfq.deliveryDate).startOf('day').toDate(),
                );
    
            const isSameDate = moment(rfq.departureDate).isSame(moment(rfq.deliveryDate), 'day');
    
            if (isSameDate) {
                departureDateIsTooEarly = true;
                departureDateIsEarlierThanDeliveryDate = true;
            }
        }
    
        if (
            [
                deliveryPortIsEmpty,
                manningIsNull,
                coveringDaysIsNull,
                deliveryDateIsEmpty,
                deliveryDateIsTooEarly,
                agentEmailIsInvalid,
                noLineItems,
                // Include departure date validations conditionally
                (rfq.departureDate && departureDateIsTooEarly),
                (rfq.departureDate && departureDateIsEarlierThanDeliveryDate),
            ].some((value) => value)
        ) {
            setValidationErrors((prev) => ({
                ...prev,
                deliveryPortIsEmpty,
                manningIsNull,
                coveringDaysIsNull,
                deliveryDateIsEmpty,
                deliveryDateIsTooEarly,
                agentEmailIsInvalid,
                noLineItems,
                ...(rfq.departureDate && {
                    departureDateIsTooEarly,
                    departureDateIsEarlierThanDeliveryDate,
                }),
            }));
    
            return { tabWithError: Tab.details };
        }
    
        return { tabWithError: null };
    };

    const focusFirstValidationError = () => {
        const errorKey = (
            Object.keys(validationErrors) as (keyof ValidationFields)[]
        ).find((key) => validationErrors?.[key]);
        if (errorKey) {
            // It's a bit tough to manage so many refs, so this is a more simple
            // vanilla JS approach at focusing the desired input element
            const input = document.querySelector<HTMLInputElement>(
                `[name="${validationErrorFieldMap[errorKey]}"]`,
            );
            input?.focus();
        }
    };

    const revalidateNoLineItemsError = (rfq?: RFQ | null) => {
        if (validationErrors.noLineItems && rfq) {
            const noLineItems =
                rfq.lineItems.every((item) => item.quantity === 0) ?? true;

            setValidationErrors((prev) => ({
                ...prev,
                noLineItems,
            }));
        }
    };

    return {
        validationErrors,
        validate,
        setValidationErrors,
        focusFirstValidationError,
        revalidateNoLineItemsError,
    };
};

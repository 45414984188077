import {
    CreateCondemnReportDto,
    IncomingItemIssueDto,
    ItemIssuePhotoDto,
    ItemIssueProblem,
    ItemIssueType,
} from '../../../apiClient/generated';
import { getItemIssueType } from './itemIssueType';
import {
    ItemIssueDetails,
    ItemIssueUIType,
} from '../ClaimOrCondemnPreparationModal';
import {
    IExtendedItemIssueDto,
    ISendingCondemnReport,
} from '../../../db/overrideTypes';
import { validate as uuidValidate } from 'uuid';
import moment from 'moment';

export const parseItemIssueDetailsToClaimDto = (
    claim: ItemIssueDetails,
    imagesArrayLength?: number,
): IncomingItemIssueDto => {
    const {
        selectedProduct,
        itemIssueProblem,
        affectedAmount,
        comment,
        order,
        deliveryPort,
        localId,
        clientId,
        isProductUsable,
    } = claim;

    const itemIssueType = claim.itemIssueType;

    if (itemIssueType === undefined) {
        throw new Error('Missing claim type field');
    }

    const isSupportedItemIssueType = itemIssueType in ItemIssueType;
    const isLocalIdV4 = localId ? uuidValidate(localId) : false;

    if (
        !selectedProduct ||
        itemIssueProblem === null ||
        itemIssueProblem === undefined ||
        !isSupportedItemIssueType ||
        !selectedProduct.itemNumber ||
        !isLocalIdV4 ||
        !localId ||
        itemIssueType !== ItemIssueUIType.Claim
    ) {
        throw new Error('Missing required claim fields from claim dto');
    } else {
        return {
            productItemNumber: selectedProduct.itemNumber,
            itemIssueProblem: itemIssueProblem as ItemIssueProblem,
            itemIssueType: ItemIssueType.Claim,
            affectedAmount: affectedAmount,
            comment: comment,
            deliveryPortCode: order
                ? order.rfq.deliveryPort?.portCode
                : deliveryPort?.portCode,
            numberOfPhotos: imagesArrayLength ?? 0,
            orderId: order?.orderId ?? null,
            localId: localId,
            clientId: clientId,
            isProductUsable: isProductUsable,
        };
    }
};

export const parseClaimDetailsToItemIssueDto = (
    claim: ItemIssueDetails,
    isOffline: boolean,
): IExtendedItemIssueDto => {
    const {
        selectedProduct,
        itemIssueProblem,
        affectedAmount,
        comment,
        order,
        localId,
        clientId,
        images,
    } = claim;
    const itemIssueType = getItemIssueType(claim);

    if (itemIssueType === undefined) {
        throw new Error('Missing claim type field');
    } else if (itemIssueType === 'condemned') {
        throw new Error("Condemn can't be mapped to item issue");
    }

    if (
        !selectedProduct ||
        itemIssueProblem === null ||
        itemIssueProblem === undefined ||
        !localId ||
        !selectedProduct.name ||
        !selectedProduct.itemNumber
    ) {
        throw new Error('Missing required claim fields from item issue dto');
    } else {
        let parsedArrayOfImages: ItemIssuePhotoDto[] = [];
        if (images) {
            parsedArrayOfImages = images.map((imageEntry) => {
                return {
                    url: '',
                    name: imageEntry.image.name,
                    size: imageEntry.image.size,
                };
            });
        }

        return {
            itemName: selectedProduct.name,
            productItemNumber: selectedProduct.itemNumber,
            orderId: order?.orderId ?? null,
            itemIssueProblem: itemIssueProblem as ItemIssueProblem,
            itemIssueType: itemIssueType,
            affectedAmount: affectedAmount,
            comment: comment,
            itemIssueStatus: 0,
            numberOfPhotos: parsedArrayOfImages.length,
            photos: parsedArrayOfImages,
            localPhotos: images,
            created: new Date().toDateString(),
            lastModified: new Date().toDateString(),
            clientId: clientId,
            isOffline: isOffline,
            localId: localId,
        };
    }
};

export const parseItemIssueDetailsToCondemnReport = (
    condemn: ItemIssueDetails,
): CreateCondemnReportDto => {
    const itemIssueType = condemn.itemIssueType;

    if (itemIssueType !== ItemIssueUIType.Condemn) {
        throw new Error("Data can't be mapped to condemn");
    }

    const {
        selectedProduct,
        affectedAmount,
        itemIssueProblem,
        comment,
        localId,
    } = condemn;

    return {
        captain: '', 
        chiefCook: '', 
        reportYearAndMonth: moment().format('YYYY-MM'),
        localId,
        items: [
            {
                itemNumber: selectedProduct.itemNumber!,
                quantity: affectedAmount,
                reason: itemIssueProblem?.toString() ?? '',
                comment: comment,
            },
        ],
        isClosed: false,
    };
};

export const parseCondemnReportToSendingCondemnReport = (
    condemn: ItemIssueDetails,
    condemnReportOfflineId: number,
): ISendingCondemnReport => {
    const itemIssueType = getItemIssueType(condemn);

    if (itemIssueType !== 'condemned') {
        throw new Error("Claim can't be mapped to condemn");
    }
    const { selectedProduct, affectedAmount, itemIssueProblem, localId } =
        condemn;
    return {
        reportPeriodDate: moment().format('YYYY-MM'),
        isClosed: false,
        localId: localId!,
        id: condemnReportOfflineId,
        items: [
            {
                id: condemnReportOfflineId ?? 0,
                itemName: selectedProduct.name!,
                itemNumber: selectedProduct.itemNumber!,
                quantity: affectedAmount!,
                reasonCode: itemIssueProblem!.toString(),
            },
        ],
    };
};

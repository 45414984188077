import '../styles/globals.css';
import '../styles/vars.css';
import type { AppProps } from 'next/app';
import { ProductDetailsProvider } from '../context/ProductDetailsContext';
import React, { useEffect } from 'react';
import { SearchProvider } from '../context/SearchContext';
import { Workbox } from 'workbox-window';
import Head from '../components/Head/Head';
import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import Router from 'next/router';
import { initializeApiClient } from '../apiClient/apiClient';
import { NotificationsProvider } from '../context/NotificationContext';
import { Tracking } from '../context/Tracking';
import UnhandledErrorBoundary from '../components/ErrorComponents/UnhandledErrorBoundary';
import {
    triggerUpdateAvailableModal,
    UpdateAvailableModal,
} from '../components/UpdateAvailableModal/UpdateAvailableModal';
import { UnsavedWorkProvider } from '../context/UnsavedWorkContext';
import { AppLoadingProvider } from '../context/AppLoadingContext';
import { fetchAndUpdateProducts } from '../db/products';
import { swMessages } from '../worker/types';
import { fetchAndUpdateAssortments } from '../db/assortments';
import { SyncStatusProvider } from '../context/SyncStatusContext';
import { FeatureFlagsProvider } from '../context/useFeatureFlags';
import { ToastProvider } from '../context/ToastContext';
import styles from '../components/Layout/Layout.module.css';
import { dataFlowEventHub, DataFlowEvents } from '../events/dataFlowEvents';
import { updateStocktakingDraftMaxCount } from '../components/StocktakingProductsList/utils/stocktakingUtils';
import { ModalProvider } from '../context/ModalContext';
import { FeatureAnnouncements } from '../components/FeatureAnnouncements/FeatureAnnouncements';
import { db } from '../db/db';
import { RestoreCashPurchaseOrderModal } from '../components/CashPurchaseInfoModals/RestoreCashPurchaseOrderModal';
import { initDatadog } from '../utils/datadog';
import { ClaimsInReceivalFlowProvider } from '../context/ClaimsInReceivalFlowContext';

initDatadog();

const onRedirectCallback = (appState: AppState) => {
    Router.replace(appState?.returnTo || '/');
};

const InitializeApp = () => {
    const { getAccessTokenSilently, logout, isAuthenticated, isLoading } =
        useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            initializeApiClient(getAccessTokenSilently, () => {
                logout({ returnTo: window.location.origin });
            });
        }
    }, [getAccessTokenSilently, logout, isAuthenticated, isLoading]);

    useEffect(() => {
        const fourHours = 4 * 60 * 60 * 1000;
        const interval = setInterval(async () => {
            await fetchAndUpdateProducts();
            await fetchAndUpdateAssortments();
        }, fourHours);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const eventsToRefreshStocktakingDraft: (keyof DataFlowEvents)[] = [
            'orderChanged',
            'receivalSubmitted',
            'condemnItemSubmitted',
            'claimItemSubmitted',
            'offlineRequestSubmitted',
        ];
        for (const event of eventsToRefreshStocktakingDraft) {
            dataFlowEventHub.addListener(event, updateStocktakingDraftMaxCount);
        }

        return () => {
            for (const event of eventsToRefreshStocktakingDraft) {
                dataFlowEventHub.removeListener(
                    event,
                    updateStocktakingDraftMaxCount,
                );
            }
        };
    });

    return <></>;
};

const App = ({ Component, pageProps }: AppProps) => {
    useEffect(() => {
        if (
            !('serviceWorker' in navigator) ||
            process.env.NODE_ENV !== 'production'
        ) {
            console.warn('Progressive Web App support is disabled');
            return;
        }

        let updateInterval: NodeJS.Timer | null = null;
        const registerWorkbox = async () => {
            const workboxInstance = new Workbox('/sw.js', { scope: '/' });
            const workbox = await workboxInstance.register();

            if ('serviceWorker' in navigator && 'SyncManager' in window) {
                try {
                    const registration = await navigator.serviceWorker.ready;
                    registration.sync.register('sendRequests');
                } catch (e) {
                    // system was unable to register for a sync,
                    // this could be an OS-level restriction
                    window.workbox.messageSW({
                        command: swMessages.sendRequestsFromDb,
                    });
                }
            } else {
                // serviceworker/sync not supported - replay requests when SW updates
                window.workbox.messageSW({
                    command: swMessages.sendRequestsFromDb,
                });
            }
            if (workbox) {
                const tenMinutesInMS = 600000;
                updateInterval = setInterval(async () => {
                    await workbox.update();
                    if (workbox.waiting || workbox.installing) {
                        triggerUpdateAvailableModal();
                    }
                }, tenMinutesInMS);
            }
        };

        registerWorkbox();

        return () => {
            if (updateInterval) {
                clearInterval(updateInterval);
            }
        };
    }, []);

    /**
     * This method removes all unsaved changes in editableOrders.
     * We want to do this on app load, beacuse it means somebody decided to remove it,
     * in prompt triggered by onbeforeunload event.
     * it's workaround.
     */
    const resetUnsavedChanges = async () => {
        const editableOrders = await db.editableOrders.toArray();
        const updatedEditableOrders = editableOrders.flatMap((item) => {
            if (item.savedRfq) {
                return {
                    ...item,
                    rfq: item.savedRfq,
                };
            } else {
                return [];
            }
        });
        db.editableOrders.bulkPut(updatedEditableOrders);
    };

    useEffect(() => {
        resetUnsavedChanges();
    }, []);

    return (
        <Auth0Provider
            domain={process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL!}
            clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
            audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
            onRedirectCallback={onRedirectCallback}
            redirectUri={`${process.env.NEXT_PUBLIC_AUTH0_BASE_URL}/auth/callback`}
            useRefreshTokens={true}
            cacheLocation="localstorage"
        >
            <UnhandledErrorBoundary>
                <FeatureFlagsProvider>
                    <UnsavedWorkProvider>
                        <ToastProvider>
                            <ModalProvider>
                                <InitializeApp />
                                <Tracking />
                                <UpdateAvailableModal />
                                <RestoreCashPurchaseOrderModal />
                                <Head />
                                <SyncStatusProvider>
                                    <SearchProvider>
                                        <ProductDetailsProvider>
                                            <NotificationsProvider>
                                                <AppLoadingProvider>
                                                    <ClaimsInReceivalFlowProvider>
                                                        <FeatureAnnouncements />

                                                        <div
                                                            className={
                                                                styles.pageContentSlided
                                                            }
                                                        >
                                                            <Component
                                                                {...pageProps}
                                                            />
                                                        </div>
                                                    </ClaimsInReceivalFlowProvider>
                                                </AppLoadingProvider>
                                            </NotificationsProvider>
                                        </ProductDetailsProvider>
                                    </SearchProvider>
                                </SyncStatusProvider>
                            </ModalProvider>
                        </ToastProvider>
                    </UnsavedWorkProvider>
                </FeatureFlagsProvider>
            </UnhandledErrorBoundary>
        </Auth0Provider>
    );
};

export default App;

import { getDataVersion, setDataVersion } from './dataVersions';
import { apiClient } from '../apiClient/apiClient';
import { mapToProduct } from '../apiClient/apiClientMappers';
import { OrderStatus, ProductItemDto } from '../apiClient/generated';
import { getUniqesFromArray } from '../components/utils/arrayMethods';
import { EditableOrder, Product } from '../types/order.types';
import { getAssortment, OrderTypes } from '../context/OrderTypes';
import { dataFlowEventHub } from '../events/dataFlowEvents';
import { db } from './db';
import { getLatestRfq } from './utils/getLatestRfq';

const addProductsInitially = async (
    newProducts: ProductItemDto[],
    newVersion: bigint,
) => {
    await db.transaction('rw', db.products, db.dataVersions, async () => {
        try {
            await db.products.clear();
            await db.products.bulkAdd(
                newProducts.map((item) => mapToProduct(item)),
            );
            await setDataVersion('Products', newVersion);
        } catch (e) {
            console.log(e);
        }
    });
};

const updateProductsIncrementally = async (
    removedProductNumbers: string[],
    newProducts: ProductItemDto[],
    newVersion: bigint,
) => {
    await db.transaction('rw', db.products, db.dataVersions, async () => {
        if (removedProductNumbers.length > 0) {
            await db.products.bulkDelete(removedProductNumbers);
        }

        if (newProducts.length > 0) {
            await db.products.bulkPut(
                newProducts.map((item) => mapToProduct(item)),
            );
        }

        await setDataVersion('Products', newVersion);
    });
};

export const fetchAndUpdateProducts = async () => {
    const productsVersion = await getDataVersion('Products');
    const productsResponse = await apiClient.getProducts(
        productsVersion?.toString(),
    );

    if (productsResponse) {
        const responseDataVersion = BigInt.asUintN(
            64,
            BigInt(productsResponse.dataVersionBigInt),
        );
        if (responseDataVersion === productsVersion) {
            return;
        }

        const { initial, incremental } = productsResponse;
        if (initial) {
            await addProductsInitially(initial, responseDataVersion);
        } else if (incremental) {
            const newProducts = [
                ...(incremental.added ?? []),
                ...(incremental.updated ?? []),
            ];

            await updateProductsIncrementally(
                incremental.removed,
                newProducts,
                responseDataVersion,
            );
        }

        dataFlowEventHub.emit('productsChanged');
    }
};

export const getAssortmentExtendedByActiveOrder = async (
    assortmentType: OrderTypes,
): Promise<string[]> => {
    
    const assortments2 = await db.assortments.toCollection().first();
    
    if (!assortments2) return []

    const assortments = { ...assortments2, 
        }

    if (!assortments) {
        return [];
    }

    const activeOrder = await db.orders
        .where('type')
        .equalsIgnoreCase(assortmentType)
        .filter((item) => item.status <= OrderStatus.FinalOrder)
        .first();

    const assortment = getAssortment(assortments, assortmentType);

    let assortmentExtendedByActiveOrder = assortment;
    const latestRfq = getLatestRfq(activeOrder);
    if (latestRfq) {
        const lineItemNumbers =
            latestRfq.lineItems?.map((item) => item.itemNumber) ?? [];

        assortmentExtendedByActiveOrder = getUniqesFromArray([
            ...assortment,
            ...lineItemNumbers,
        ]);
    }

    return assortmentExtendedByActiveOrder;
};

export const getAvailableProducts = async (
    assortmentType: OrderTypes,
    hiddenProducts: string[],
): Promise<Product[]> => {
    const assortment = await getAssortmentExtendedByActiveOrder(assortmentType);
    const productsFromAssortment = await db.products
        .where('itemNumber')
        .anyOf(assortment)
        .toArray();

    return (
        productsFromAssortment?.filter(
            ({ itemNumber }) => !hiddenProducts.includes(itemNumber),
        ) ?? []
    );
};

export const calculateProductAmountRatio = (
    editableOrder: EditableOrder,
    newManning: number,
    newCoveringDays: number,
): number => {
    let ratio = 1;
    if (newManning && editableOrder?.rfq?.manning) {
        ratio = (ratio / editableOrder?.rfq?.manning) * newManning;
    }

    if (newCoveringDays && editableOrder?.rfq?.coveringDays) {
        ratio = (ratio / editableOrder?.rfq?.coveringDays) * newCoveringDays;
    }

    return ratio;
};

import { Product } from '../../../types/order.types';
import { CategoryDetailsLevel3 } from '../FiltersModal';

interface CategoryMap {
    categoryIdLevel2: number;
    categoryNameLevel2: string;
    categoriesLevel3: CategoryDetailsLevel3[];
}

export const parseProductsToCategories = (
    products: Product[],
): CategoryMap[] => {
    if (!products) return [];

    const mappedProductsByCategoryLevel2 = Array.from(
        new Map(
            products.map((item) => [item.categoryLevel2['number'], item]),
        ).values(),
    );

    const getProductsByCategoryLevel2 = (categoryId: number) =>
        products.filter(
            ({ categoryLevel2 }) => categoryLevel2.number === categoryId,
        );

    const mapCategoriesLevel3 = (item: Product) => {
        const productsInCategoryLevel3 = getProductsByCategoryLevel2(
            item.categoryLevel2.number,
        );

        const mappedProductsByCategoryLevel3 = Array.from(
            new Map(
                productsInCategoryLevel3.map((item) => [
                    item.categoryLevel3['number'],
                    item,
                ]),
            ).values(),
        ).map(({ categoryLevel3 }) => ({
            name: categoryLevel3.text,
            id: categoryLevel3.number,
        })).sort((a, b) =>  a.id - b.id)
        
        return mappedProductsByCategoryLevel3;
    };

    const mappedCategories = mappedProductsByCategoryLevel2.map((item) => ({
        categoryIdLevel2: item.categoryLevel2.number,
        categoryNameLevel2: item.categoryLevel2.text,
        categoriesLevel3: mapCategoriesLevel3(item),
    }));

    return mappedCategories;
};

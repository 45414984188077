import useSWR from 'swr';
import { SWRCacheKeys, swrDefaultConfig } from '../events/dataFlowEvents';
import { apiClient } from '../apiClient/apiClient';
import { db } from '../db/db';
import { VesselMetadataResponse } from '../apiClient/generated';
import { fetchAndUpdateCurrencies } from '../db/externalCurrenciesWithRates';

type RequiredNotNull<T> = {
    [P in keyof T]: NonNullable<T[P]>;
};

type VesselMetadata = RequiredNotNull<Required<VesselMetadataResponse>>;

type KeyOfVesselMetadata = keyof VesselMetadata;

const isVesselMetadataKey = (key: string): key is KeyOfVesselMetadata => {
    return key in defaultVesselMetadata;
};

const defaultVesselMetadata: VesselMetadata = {
    company: 'Unknown...',
    dailyBudgetRateInVesselCurrency: 0,
    dayAllowanceCategory1Amount: 0,
    dayAllowanceCategory1Name: 'Unknown...',
    dayAllowanceCategory1Unit: 'Unknown...',
    dayAllowanceCategory2Amount: 0,
    dayAllowanceCategory2Name: 'Unknown...',
    dayAllowanceCategory2Unit: 'Unknown...',
    dayAllowanceCategory3Amount: 0,
    dayAllowanceCategory3Name: 'Unknown...',
    dayAllowanceCategory3Unit: 'Unknown...',
    dayAllowanceCategory4Amount: 0,
    dayAllowanceCategory4Name: 'Unknown...',
    dayAllowanceCategory4Unit: 'Unknown...',
    imoNo: 0,
    monthCashAllowanceInVesselCurrency: 0,
    seaStarContact: 'Unknown...',
    seaStarEmail: 'Unknown...',
    seaStarOfficeHours: 'Unknown...',
    seaStarPhone: 'Unknown...',
    standardManning: 0,
    vesselCurrency: 'Unknown...',
    vesselEmail: 'Unknown...',
    vesselName: 'Unknown...',
    vesselOwner: 'Unknown...',
    vesselPhone: 'Unknown...',
    isDeleted: false,
};

const fourHours = 4 * 60 * 60 * 1000;

export const getSeasonPeriods = async () => {
    try { 
        const seasonalPeriods = await apiClient.getSeasonPeriods();   
        return seasonalPeriods
    } catch (error) {
        console.error(error)
    }
}

export const getVesselMetadata = async () => {
    try {
        const updatedMetadata = await apiClient.getVesselMetadata();

        await db.transaction('rw', db.vesselMetadata, async () => {
            await db.vesselMetadata.clear();
            await db.vesselMetadata.add(updatedMetadata);
        });
    } catch (error) {
        console.error(error);
    }
    /**
     * Currencies are fetched here because we need to load them into the base when initializing
     * the application. This function is called in useVesselMetaData hook because we need validated
     * vesselMetadata to fetch and update currencies correctly.
     */
    await fetchAndUpdateCurrencies();

    return db.vesselMetadata.toCollection().first();
};

export const useSeasonalPeriods = () => {
    const { data: vesselSeasonalPeriods } = useSWR(SWRCacheKeys.seasonalPeriod, getSeasonPeriods, {
        ...swrDefaultConfig,
        dedupingInterval: fourHours,
        focusThrottleInterval: fourHours,
    }); 

    const parseVesselMetadataResponseToVesselMetadata = (
        vesselMetadataResponse: VesselMetadataResponse | undefined,
    ): VesselMetadata => {
        if (!vesselMetadataResponse) {
            return defaultVesselMetadata;
        }

        const entries = Object.entries(vesselMetadataResponse);

        const entriesWithDefaults = entries.map(([key, value]) => {
            if (
                (typeof value !== 'undefined' && value !== null) ||
                !isVesselMetadataKey(key)
            ) {
                return [key, value];
            }

            const defaultValue = defaultVesselMetadata[key];

            return [key, defaultValue];
        });

        const parsedVesselMetadata = Object.fromEntries(entriesWithDefaults);

        return parsedVesselMetadata;
    };

    return  {
        data: vesselSeasonalPeriods
    }
    
};

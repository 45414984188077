import React, { ReactNode } from 'react';
import { useSearch } from '../../context/SearchContext';
import { FailedRequestModal } from '../ErrorComponents/FailedRequestModal';
import PageTopbar from '../PageTopbar/PageTopbar';
import SearchResults from '../SearchResults/SearchResults';
import SideNavigation from '../SideNavigation/SideNavigation';
import styles from './Layout.module.css';
import { FeatureFlags, useFeatureFlags } from '../../context/useFeatureFlags';
import Error404 from '../ErrorComponents/Error404';

interface Props {
    children: ReactNode;
    requiredFeatureFlag?: keyof FeatureFlags;
}

const Layout = ({ children, requiredFeatureFlag }: Props) => {
    const { shouldShowResults, searchQuery } = useSearch();    
    const { featureFlags } = useFeatureFlags();
    const isRestrictedByFeatureFlag =
        featureFlags &&
        requiredFeatureFlag &&
        !featureFlags?.[requiredFeatureFlag];

    if (isRestrictedByFeatureFlag) {
        return <Error404 />;
    }

    return (
        <div className={styles.container}>
            <FailedRequestModal />
            <SideNavigation />
            <div className={styles.pageContent} data-testid="pageContent">
                <PageTopbar />
                <section>
                    {shouldShowResults ? (
                        <SearchResults searchQuery={searchQuery} />
                    ) : (
                        children
                    )}
                </section>
            </div>
        </div>
    );
};

export default Layout;

import { NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Layout from '../../../../../components/Layout/Layout';
import Modal from '../../../../../components/Modal/Modal';
import { ReceivedProductsChecklist } from '../../../../../components/ProductsList/ReceivedProductsChecklist/ReceivedProductsChecklist';
import { adjustOrderToDisplay } from '../../../../../components/utils/adjustOrderToDisplay';
import useOrderType from '../../../../../hooks/useOrderType';
import ReceivalDetailsModal from '../../../../../components/Receival/ReceivalModals/ReceivalDetailsModal';
import MissingItemSearchModal from '../../../../../components/Receival/ReceivalModals/MissingItemSearchModal';
import { AddCommentToReveivedItem } from '../../../../../components/ProductsList/ReceivedProductsChecklist/AddCommentToReveivedItem';
import AdditionalNotes from '../../../../../components/Receival/ReceivalModals/AdditionalNotes';
import { ReceivalSummary } from '../../../../../components/Receival/ReceivalModals/ReceivalSummary';
import { useOrder } from '../../../../../hooks/useOrder';
import { OrderTypes } from '../../../../../context/OrderTypes';
import { SupplierExperience } from '../../../../../components/Receival/ReceivalModals/SupplierExperience/SupplierExperience';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useFeatureFlags } from '../../../../../context/useFeatureFlags';
import PerformanceReportStarRatingModal from '../../../../../components/Receival/PerformanceReportModals/PerformanceReportStarRatingModal';
import { RatingKey } from '../../../../../types/receivedOrder.types';
import {
    getOrderPath,
    getOrderReceivalPath,
} from '../../../../../components/utils/orderPathsGenerator';
import Error404 from '../../../../../components/ErrorComponents/Error404';
import { parseOrderStatus } from '../../../../../context/utils/orderStatusParser';

export enum ReceivalStep {
    Start = 'start',
    Checklist = 'checklist',
    Add = 'add',
    Summary = 'summary',
}

export enum PerformanceReportStep {
    FreshProvision = 'performance-report-fresh',
    DryProvision = 'performance-report-dry',
    FrozenProvision = 'performance-report-frozen',
    PackagingAndLabels = 'performance-report-packaging',
    OverallPerformance = 'performance-report-overall',
    SupplierExperience = 'performance-report-experience',
    Note = 'performance-report-note',
}

//Note - Creating custom item was deleted in this PR

const ReceivalStepPage: NextPage = () => {
    const { featureFlags } = useFeatureFlags();
    const { orderIdFromQuery, activeOrderType } = useOrderType();
    const { query, push } = useRouter();
    const [activeStep, setActiveStep] = useState<
        ReceivalStep | PerformanceReportStep
    >(ReceivalStep.Start);

    const [commentViewIsActive, setCommentViewIsActive] = useState<{
        isOpen: boolean;
        itemNumber?: string;
    }>({
        isOpen: false,
    });

    const { data: order } = useOrder(orderIdFromQuery);
    const orderToDisplay = order ? adjustOrderToDisplay(order) : undefined;

    const isPerformanceReportPartOfTheFlow =
        !order?.isPerformanceReportCreated &&
        activeOrderType === OrderTypes.provision &&
        Boolean(featureFlags?.performanceReport);

    const setInitialStepFromQuery = () => {
        if (activeStep && query?.receivalStep) {
            const queryString = query.receivalStep.toString();

            const isQueryStringOkWithTabEnum = (
                queryString: unknown,
            ): queryString is ReceivalStep | PerformanceReportStep => {
                return Object.values({
                    ...ReceivalStep,
                    ...PerformanceReportStep,
                }).includes(
                    queryString as ReceivalStep | PerformanceReportStep,
                );
            };

            const isPerformanceReportStep = (
                queryString: unknown,
            ): queryString is PerformanceReportStep => {
                return Object.values(PerformanceReportStep).includes(
                    queryString as PerformanceReportStep,
                );
            };

            if (
                !isQueryStringOkWithTabEnum(queryString) ||
                (isPerformanceReportStep(queryString) &&
                    !isPerformanceReportPartOfTheFlow)
            ) {
                setActiveStep(ReceivalStep.Start);
                return;
            }

            if (isPerformanceReportStep(queryString)) {
                setActiveStep(queryString);
                return;
            }

            setActiveStep(queryString);
        }
    };

    useEffect(() => {
        setInitialStepFromQuery();
    }, [query]);

    const stepsNumber = isPerformanceReportPartOfTheFlow ? 10 : 3;

    if (!orderToDisplay) {
        return <Error404 />;
    }

    const redirectToOrderSummaryPage = () => {
        push(getOrderPath(orderToDisplay));
    };

    const redirectToStep = (step: ReceivalStep | PerformanceReportStep) => {
        push(getOrderReceivalPath(orderToDisplay, step));
    };

    const { canOpenReceivalFlow } = parseOrderStatus(orderToDisplay);
    const isReceivalAvailable =
        canOpenReceivalFlow && order?.isSsPoNumberAvailableForOrder;

    return isReceivalAvailable ? (
        <Layout>
            <Modal isOpen noHeader onClose={redirectToOrderSummaryPage} large>
                {activeStep == ReceivalStep.Start && (
                    <ReceivalDetailsModal
                        orderToDisplay={orderToDisplay}
                        onClose={redirectToOrderSummaryPage}
                        onContinue={() =>
                            redirectToStep(ReceivalStep.Checklist)
                        }
                        steps={stepsNumber}
                    />
                )}
                {activeStep === ReceivalStep.Checklist && (
                    <>
                        {commentViewIsActive.isOpen ? (
                            <AddCommentToReveivedItem
                                setCommentViewIsActive={setCommentViewIsActive}
                                orderIdFromQuery={Number(orderIdFromQuery)}
                                commentViewIsActive={commentViewIsActive}
                            />
                        ) : (
                            <ReceivedProductsChecklist
                                orderToDisplay={orderToDisplay}
                                setCommentViewIsActive={setCommentViewIsActive}
                                steps={stepsNumber}
                                onBack={() => {
                                    redirectToStep(ReceivalStep.Start);
                                }}
                                onContinue={() => {
                                    if (isPerformanceReportPartOfTheFlow) {
                                        redirectToStep(
                                            PerformanceReportStep.FreshProvision,
                                        );
                                    } else {
                                        redirectToStep(ReceivalStep.Summary);
                                    }
                                }}
                            />
                        )}
                    </>
                )}
                {activeStep === ReceivalStep.Add && (
                    <MissingItemSearchModal
                        selectedOrderId={Number(orderToDisplay.orderId)}
                    />
                )}
                {activeStep === PerformanceReportStep.Note && (
                    <AdditionalNotes
                        selectedOrderId={Number(orderToDisplay.orderId)}
                        steps={stepsNumber}
                        onBack={() => {
                            redirectToStep(
                                PerformanceReportStep.SupplierExperience,
                            );
                        }}
                        onContinue={() => {
                            redirectToStep(ReceivalStep.Summary);
                        }}
                    />
                )}
                {activeOrderType === OrderTypes.provision && (
                    <>
                        {activeStep ===
                            PerformanceReportStep.FreshProvision && (
                            <PerformanceReportStarRatingModal
                                ratingKey={RatingKey.qualityFresh}
                                onBack={() =>
                                    redirectToStep(ReceivalStep.Checklist)
                                }
                                onContinue={() =>
                                    redirectToStep(
                                        PerformanceReportStep.DryProvision,
                                    )
                                }
                            />
                        )}
                        {activeStep === PerformanceReportStep.DryProvision && (
                            <PerformanceReportStarRatingModal
                                ratingKey={RatingKey.qualityDry}
                                onBack={() =>
                                    redirectToStep(
                                        PerformanceReportStep.FreshProvision,
                                    )
                                }
                                onContinue={() =>
                                    redirectToStep(
                                        PerformanceReportStep.FrozenProvision,
                                    )
                                }
                            />
                        )}
                        {activeStep ===
                            PerformanceReportStep.FrozenProvision && (
                            <PerformanceReportStarRatingModal
                                ratingKey={RatingKey.qualityFrozen}
                                onBack={() =>
                                    redirectToStep(
                                        PerformanceReportStep.DryProvision,
                                    )
                                }
                                onContinue={() =>
                                    redirectToStep(
                                        PerformanceReportStep.PackagingAndLabels,
                                    )
                                }
                            />
                        )}
                        {activeStep ===
                            PerformanceReportStep.PackagingAndLabels && (
                            <PerformanceReportStarRatingModal
                                ratingKey={RatingKey.qualityPackingMarking}
                                onBack={() =>
                                    redirectToStep(
                                        PerformanceReportStep.FrozenProvision,
                                    )
                                }
                                onContinue={() =>
                                    redirectToStep(
                                        PerformanceReportStep.OverallPerformance,
                                    )
                                }
                            />
                        )}
                        {activeStep ===
                            PerformanceReportStep.OverallPerformance && (
                            <PerformanceReportStarRatingModal
                                ratingKey={RatingKey.overAllPerformance}
                                onBack={() =>
                                    redirectToStep(
                                        PerformanceReportStep.PackagingAndLabels,
                                    )
                                }
                                onContinue={() =>
                                    redirectToStep(
                                        PerformanceReportStep.SupplierExperience,
                                    )
                                }
                            />
                        )}
                        {activeStep ===
                            PerformanceReportStep.SupplierExperience && (
                            <SupplierExperience
                                steps={stepsNumber}
                                onBack={() =>
                                    redirectToStep(
                                        PerformanceReportStep.OverallPerformance,
                                    )
                                }
                                onContinue={() =>
                                    redirectToStep(PerformanceReportStep.Note)
                                }
                            />
                        )}
                    </>
                )}
                {activeStep === ReceivalStep.Summary && (
                    <>
                        {commentViewIsActive.isOpen ? (
                            <AddCommentToReveivedItem
                                setCommentViewIsActive={setCommentViewIsActive}
                                orderIdFromQuery={Number(orderIdFromQuery)}
                                commentViewIsActive={commentViewIsActive}
                            />
                        ) : (
                            <ReceivalSummary
                                orderToDisplay={orderToDisplay}
                                setCommentViewIsActive={setCommentViewIsActive}
                                isPerformanceReportPartOfTheFlow={
                                    isPerformanceReportPartOfTheFlow
                                }
                                onBack={() => {
                                    if (isPerformanceReportPartOfTheFlow) {
                                        redirectToStep(
                                            PerformanceReportStep.Note,
                                        );
                                    } else {
                                        redirectToStep(ReceivalStep.Checklist);
                                    }
                                }}
                                steps={stepsNumber}
                            />
                        )}
                    </>
                )}
            </Modal>
        </Layout>
    ) : (
        <Error404 />
    );
};

export default withAuthenticationRequired(ReceivalStepPage);

import * as React from 'react';
import styles from './statusInfo.module.css';
import { DisplayedStatus } from '../../context/utils/displayedStatus';

interface Props {
    displayedStatus: DisplayedStatus;
    isSendingOffline?: boolean;
    maxWidth?: number;
}

const StatusInfo: React.FC<Props> = ({
    displayedStatus,
    maxWidth,
    isSendingOffline,
}) => {
    const getClassNames = (statusColor: DisplayedStatus) => {
        if (isSendingOffline) {
            return {
                boxClassName: [styles.statusInfoBox, styles.offlineGrey].join(
                    ' ',
                ),
                textClassName: [styles.textStatus, styles.offlineGrey].join(
                    ' ',
                ),
            };
        }
        switch (statusColor) {
            case DisplayedStatus.notSent: {
                return {
                    boxClassName: [styles.statusInfoBox, styles.purple].join(
                        ' ',
                    ),
                    textClassName: [styles.textStatus, styles.purple].join(' '),
                };
            }
            case DisplayedStatus.awaitingAction: {
                return {
                    boxClassName: [styles.statusInfoBox, styles.orange].join(
                        ' ',
                    ),
                    textClassName: [styles.textStatus, styles.orange].join(' '),
                };
            }
            case DisplayedStatus.awaitingReceival: {
                return {
                    boxClassName: [styles.statusInfoBox, styles.orange].join(
                        ' ',
                    ),
                    textClassName: [styles.textStatus, styles.orange].join(' '),
                };
            }
            case DisplayedStatus.sentToGarrets:
            case DisplayedStatus.pending:
            case DisplayedStatus.receival:
            case DisplayedStatus.cancelledByVessel:
            case DisplayedStatus.cancelledByGarrets:
            case DisplayedStatus.orderForConfirmation:
            case DisplayedStatus.draft: {
                return {
                    boxClassName: [styles.statusInfoBox, styles.grey].join(' '),
                    textClassName: [styles.textStatus, styles.grey].join(' '),
                };
            }
            case DisplayedStatus.completed: {
                return {
                    boxClassName: [
                        styles.statusInfoBox,
                        styles.grey,
                        styles.completed,
                    ].join(' '),
                    textClassName: [styles.textStatus, styles.grey].join(' '),
                };
            }
            case DisplayedStatus.sendingOffline: {
                return {
                    boxClassName: [
                        styles.statusInfoBox,
                        styles.offlineGrey,
                    ].join(' '),
                    textClassName: [styles.textStatus, styles.offlineGrey].join(
                        ' ',
                    ),
                };
            }
            case DisplayedStatus.sendingOnline: {
                return {
                    boxClassName: [
                        styles.statusInfoBox,
                        styles.statusInfoBoxAnimated,
                        styles.lightBlue,
                    ].join(' '),
                    textClassName: [styles.textStatus, styles.offlineGrey].join(
                        ' ',
                    ),
                };
            }
            case DisplayedStatus.submitted: {
                return {
                    boxClassName: [styles.statusInfoBox, styles.green].join(
                        ' ',
                    ),
                    textClassName: [styles.textStatus, styles.grey].join(' '),
                };
            }
            default: {
                return {
                    boxClassName: styles.statusInfoBox,
                    textClassName: styles.textStatus,
                };
            }
        }
    };

    const statusLabelClassNames = getClassNames(displayedStatus);

    const getLabelText = (displayedStatus: DisplayedStatus) => {
        if (isSendingOffline) {
            return 'sending';
        }
        if (displayedStatus === DisplayedStatus.sendingOffline) {
            return 'sending';
        }
        if (displayedStatus === DisplayedStatus.notSent) {
            return 'draft';
        }
        return displayedStatus;
    };

    return (
        <div
            className={statusLabelClassNames.boxClassName}
            style={{ maxWidth }}
        >
            <p className={statusLabelClassNames.textClassName}>
                {getLabelText(displayedStatus)}
            </p>
        </div>
    );
};

export default StatusInfo;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Created
 *
 * 1 = SentToGarrets
 *
 * 2 = Completed
 *
 * 3 = Rejected
 */
export enum ItemIssueStatus {
    Created = 0,
    SentToGarrets = 1,
    Completed = 2,
    Rejected = 3,
}

import { OrderStatus } from '../../apiClient/generated';
import { isIncomingOrderDto } from '../../worker/requestQueue/typeguards';
import { QueueItem } from '../SyncStatusContext';
import {
    OrderToDisplay,
    OrderToDisplayInOrdersList,
} from '../../types/order.types';
import { OrderTypes } from '../OrderTypes';
import { DisplayedStatus } from './displayedStatus';

export interface ParsedStatus {
    displayedStatus: DisplayedStatus;
    isLocked: boolean;
    canReset: boolean;
    shouldDisplayChangelog: boolean;
    isRfqFlowCompleted: boolean;
    canOpenReceivalFlow: boolean;
    isReceived: boolean;
}

export const isOrderInOnlineQueue = (
    order: OrderToDisplay | OrderToDisplayInOrdersList,
    queue?: QueueItem[],
) => {
    if (!queue) {
        return;
    }

    const orderOnlineQueue = queue.filter((item) =>
        isIncomingOrderDto(item.request),
    );

    if (order.localOrderId) {
        return orderOnlineQueue.find((item) => {
            const { request } = item;
            if (isIncomingOrderDto(request) && request.localId) {
                return String(request.localId) === order.localOrderId;
            }
        });
    }
};

export const parseOrderStatus = (
    order?: OrderToDisplay | OrderToDisplayInOrdersList,
    queue?: QueueItem[],
): ParsedStatus => {
    if (!order)
        return {
            displayedStatus: DisplayedStatus.unknownStatus,
            isLocked: true,
            canReset: false,
            shouldDisplayChangelog: false,
            isRfqFlowCompleted: false,
            canOpenReceivalFlow: false,
            isReceived: false,
        };

    const isOrderPending = isOrderInOnlineQueue(order, queue);

    if (isOrderPending) {
        return {
            displayedStatus: DisplayedStatus.sendingOnline,
            isLocked: true,
            canReset: false,
            shouldDisplayChangelog: false,
            isRfqFlowCompleted: false,
            canOpenReceivalFlow: false,
            isReceived: false,
        };
    }

    switch (order?.status) {
        case OrderStatus.Blank:
            return {
                displayedStatus: DisplayedStatus.notSent,
                isLocked: false,
                canReset: true,
                shouldDisplayChangelog: false,
                isRfqFlowCompleted: false,
                canOpenReceivalFlow: false,
                isReceived: false,
            };
        case OrderStatus.Created:
        case OrderStatus.RfqSent:
        case OrderStatus.FinalOrder:
            return {
                displayedStatus: DisplayedStatus.sentToGarrets,
                isLocked: true,
                canReset: false,
                shouldDisplayChangelog: false,
                isRfqFlowCompleted: false,
                canOpenReceivalFlow: false,
                isReceived: false,
            };
        case OrderStatus.OrderForReview:
            return {
                displayedStatus: DisplayedStatus.awaitingAction,
                isLocked: false,
                canReset: false,
                shouldDisplayChangelog: true,
                isRfqFlowCompleted: false,
                canOpenReceivalFlow: false,
                isReceived: false,
            };
        case OrderStatus.Receipt:
            return {
                displayedStatus: DisplayedStatus.completed,
                isLocked: true,
                canReset: false,
                shouldDisplayChangelog: false,
                isRfqFlowCompleted: true,
                canOpenReceivalFlow: false,
                isReceived: Boolean(order.receival),
            };
        case OrderStatus.OrderForConfirmation:
            return {
                displayedStatus: order.type === OrderTypes.provision
                    ? DisplayedStatus.awaitingReceival
                    : DisplayedStatus.completed,
                isLocked: true,
                canReset: false,
                shouldDisplayChangelog: false,
                isRfqFlowCompleted: true,
                // For now, only Provision orders have receival enabled
                canOpenReceivalFlow: order.type === OrderTypes.provision,
                isReceived: false,
            };
        case OrderStatus.ReceiptInProgress:
            return {
                displayedStatus: order.type === OrderTypes.provision
                ? DisplayedStatus.awaitingReceival
                : DisplayedStatus.completed,
                isLocked: true,
                canReset: false,
                shouldDisplayChangelog: false,
                isRfqFlowCompleted: true, 
                canOpenReceivalFlow: false,
                isReceived: false,
            };
        case OrderStatus.CancelledByVessel:
            return {
                displayedStatus: DisplayedStatus.cancelledByVessel,
                isLocked: true,
                canReset: false,
                shouldDisplayChangelog: false,
                isRfqFlowCompleted: true,
                canOpenReceivalFlow: false,
                isReceived: false,
            };
        case OrderStatus.CancelledByGarrets:
            return {
                displayedStatus: DisplayedStatus.cancelledByGarrets,
                isLocked: true,
                canReset: false,
                shouldDisplayChangelog: false,
                isRfqFlowCompleted: true,
                canOpenReceivalFlow: false,
                isReceived: false,
            };
        default:
            return {
                displayedStatus: DisplayedStatus.unknownStatus,
                isLocked: true,
                canReset: false,
                shouldDisplayChangelog: false,
                isRfqFlowCompleted: true,
                canOpenReceivalFlow: false,
                isReceived: false,
            };
    }
};

import { createContext, useContext, useEffect, useState } from 'react';
import { WorkboxMessage, MessageType } from '../worker/utils/emitEventToWindow';
import { OrderTypes } from './OrderTypes';
import { IRequestsQueueEntry } from '../worker/requestQueue/requestQueue';
import { swMessages } from '../worker/types';
import { db } from '../db/db';
import { OrderStatus } from '../apiClient/generated';
import { BodyCopy } from '../worker/requestQueue/types';
import {
    isCondemnReportDto,
    isCreateCashPurchaseDto,
    isImageRequest,
    isIncomingItemIssueDto,
    isIncomingOrderDto,
    isManningReportDto,
    isReceival,
    isStocktakingReportDto,
} from '../worker/requestQueue/typeguards';
import {
    FailedRequestModalMetadata,
    useFailedRequestModal,
} from '../hooks/useFailedRequestModal';
import { getEditableOrderById } from '../db/editableOrders';
import { dataFlowEventHub } from '../events/dataFlowEvents';
import { handleClaimRequest } from './utils/requestHandlers';
import { useManningReports } from '../hooks/useManningReports';
import { useStocktakingReports } from '../hooks/useStocktakingReports';
import { useFeatureFlags } from './useFeatureFlags';
import { getClaimByLocalId, useClaims } from '../hooks/useClaims';
import { useCashPurchaseOrders } from '../hooks/useCashPurchaseOrders';
import { ModalType, useModalContext } from './ModalContext';
import {
    ClaimSubmissionResult,
    handleClaimValidationError,
} from '../components/ClaimOrCondemnPreparationModal/utils/submitClaim';

export enum RequestType {
    provisionOrder = 'provisionOrder',
    bondedOrder = 'bondedOrder',
    nonFoodOrder = 'nonFoodOrder',
    seasonalOrder = 'seasonalOrderSeasonal',
    claim = 'claim',
    receival = 'receival',
    file = 'file',
    manning = 'manning',
    stocktaking = 'stocktaking',
    condemn = 'condemn',
    cashPurchase = 'cashPurchase',
}

export interface QueueItem {
    request: BodyCopy;
    requestType: RequestType;
    entryId: number;
    requestRouteName: string;
}

export interface QueueStatus {
    onlineQueue: QueueItem[];
    offlineQueue: QueueItem[];
    requestInProgress: QueueItem | undefined;
}

type ContextValue = {
    isOnline: boolean;
    queueStatus: QueueStatus;
    showOfflineNotification: boolean;
    hideNotificationPermanently: () => void;
    revertOfflineOrderToDraftStage: (localIdToRemove: string) => Promise<void>;
    putFailedRequestModalMetadata: (
        metadata?: FailedRequestModalMetadata,
    ) => void;
    failedRequestModalMetadata: FailedRequestModalMetadata;
};

const initialQueueStatus: QueueStatus = {
    onlineQueue: [],
    offlineQueue: [],
    requestInProgress: undefined,
};

const SyncStatusContext = createContext<ContextValue>({
    isOnline: true,
    queueStatus: initialQueueStatus,
    showOfflineNotification: false,
    hideNotificationPermanently: () => {},
    revertOfflineOrderToDraftStage: async () => {},
    putFailedRequestModalMetadata: () => {},
    failedRequestModalMetadata: null,
});

export const SyncStatusProvider: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
}) => {
    const { openGlobalModal } = useModalContext();

    const { putFailedRequestModalMetadata, failedRequestModalMetadata } =
        useFailedRequestModal();

    const { mutate: refreshClaims } = useClaims();

    const { featureFlags } = useFeatureFlags();

    const { refreshManningReports } = useManningReports({
        withReopen: featureFlags?.manningReopen,
    });

    const { revalidateStocktakingReports } = useStocktakingReports();

    const { revalidateCashPurchaseOrders } = useCashPurchaseOrders();

    const [isOnline, setIsOnline] = useState(true);
    const [showOfflineNotification, setShowOfflineNotification] =
        useState(true);

    const [notificationWasHiddenByUser, setNotificationWasHiddenByUser] =
        useState(false);

    const hideNotificationPermanently = () => {
        setNotificationWasHiddenByUser(true);
    };

    const [queueStatus, setQueueStatus] =
        useState<QueueStatus>(initialQueueStatus);

    const isValidMessageFromWorkbox = (
        data: unknown,
    ): data is WorkboxMessage => {
        return (data as WorkboxMessage).messageType !== undefined;
    };

    const getRequestType = (request: BodyCopy) => {
        if (isIncomingOrderDto(request)) {
            switch (request.type.toLowerCase()) {
                case OrderTypes.provision.toLowerCase():
                    return RequestType.provisionOrder;
                case OrderTypes.bonded.toLowerCase():
                    return RequestType.bondedOrder;
                case OrderTypes.nonFood.toLowerCase():
                    return RequestType.nonFoodOrder;
                case OrderTypes.season.toLowerCase():
                    return RequestType.seasonalOrder;
            }
        }

        if (isIncomingItemIssueDto(request)) {
            return RequestType.claim;
        }

        if (isCondemnReportDto(request)) {
            return RequestType.condemn;
        }

        if (isReceival(request)) {
            return RequestType.receival;
        }

        if (isImageRequest(request)) {
            return RequestType.file;
        }

        if (isManningReportDto(request)) {
            return RequestType.manning;
        }

        if (isStocktakingReportDto(request)) {
            return RequestType.stocktaking;
        }

        if (isCreateCashPurchaseDto(request)) {
            return RequestType.cashPurchase;
        }
    };

    const updateConnectionStatus = () => {
        setIsOnline(navigator.onLine);
    };

    /**
     * get offlineQue, compare with db.offlineOrders and remove offlineOrders without matching request
     * this is where we actually remove ofline orders when theyre sent on sync event
     */
    const syncOfflineOrdersWithRequestsDb = async (
        updatedQueue: QueueItem[],
    ) => {
        const offlineOrders = (await db.editableOrders.toArray()).filter(
            (item) => item.isSending,
        );
        const orderLocalIdsInQueue = updatedQueue.map((item) => {
            if (isIncomingOrderDto(item.request)) {
                return item.request.localId;
            }
        });

        const offlineOrdersWithoutMatchingRequest = offlineOrders.filter(
            (offlineOrder) =>
                !orderLocalIdsInQueue.includes(offlineOrder.localOrderId),
        );

        if (offlineOrdersWithoutMatchingRequest.length === 0) {
            return;
        }

        for (const item of offlineOrdersWithoutMatchingRequest) {
            if (item.localOrderId) {
                await db.editableOrders
                    .where('localOrderId')
                    .equals(item.localOrderId)
                    .delete();
                dataFlowEventHub.emit('editableOrderChanged', {
                    localOrderId: item.localOrderId,
                    orderId: item.orderId,
                });
                dataFlowEventHub.emit('editableOrdersChanged');
            }
        }
    };

    const getOnlineQueueContents = async () => {
        const res = await window.workbox.messageSW({
            command: swMessages.getRequestQueueContent,
        });

        const onlineQueueParsed: QueueItem[] = res.map(
            (item: IRequestsQueueEntry) => {
                // this check is just for case.
                if (!item.bodyCopy) {
                    return null;
                }

                return {
                    request: item.bodyCopy,
                    requestType: getRequestType(item.bodyCopy),
                    entryId: item.entryId,
                };
            },
        );

        setQueueStatus((prev) => ({
            ...prev,
            onlineQueue: onlineQueueParsed.filter((item) => item),
        }));
    };

    const getOfflineQueueContents = async () => {
        const res = await window.workbox.messageSW({
            command: swMessages.getOfflineRequestsContent,
        });

        const offlineQueueParsed: QueueItem[] = JSON.parse(res).map(
            (item: IRequestsQueueEntry) => {
                // this check is just for case.
                if (!item.bodyCopy) {
                    return null;
                }

                return {
                    request: item.bodyCopy,
                    requestType: getRequestType(item.bodyCopy),
                    entryId: item.entryId,
                };
            },
        );

        const updatedQueue = offlineQueueParsed.filter((item) => item);

        if (updatedQueue.length < queueStatus.offlineQueue.length) {
            dataFlowEventHub.emit('offlineRequestSubmitted');
        }

        setQueueStatus((prev) => ({
            ...prev,
            offlineQueue: updatedQueue,
        }));

        await syncOfflineOrdersWithRequestsDb(updatedQueue);
    };

    const revertOfflineOrderToDraftStage = async (localIdToRemove: string) => {
        const order = await db.offlineOrders
            .where('localOrderId')
            .equals(localIdToRemove)
            .first();

        if (!order) {
            return;
        }

        await db.offlineOrders
            .where('localOrderId')
            .equals(localIdToRemove)
            .delete();

        dataFlowEventHub.emit('offlineOrderChanged', {
            localOrderId: localIdToRemove,
        });
        dataFlowEventHub.emit('offlineOrdersChanged');

        const editableOrder = {
            ...order,
            status: order.orderId
                ? OrderStatus.OrderForReview
                : OrderStatus.Blank,
        };

        try {
            await db.editableOrders.add(editableOrder);

            window.workbox.messageSW({
                command: swMessages.removeRequestFromOfflineQueueByLocalId,
                payload: localIdToRemove,
            });

            dataFlowEventHub.emit('editableOrderChanged', {
                localOrderId: editableOrder.localOrderId,
                orderId: editableOrder.orderId,
            });
        } catch (error) {
            console.error(error);
        }
    };

    const onClaimValidationFailed = async (workboxMessage: WorkboxMessage) => {
        if (workboxMessage.localId && workboxMessage.body) {
            const failedClaim = await getClaimByLocalId(workboxMessage.localId);

            const claimErrorType = handleClaimValidationError(
                workboxMessage.body.toString(),
            );

            const claimValidationModalTitle = 'Your claim request failed';
            let claimValidationModalDescription;

            switch (claimErrorType) {
                case ClaimSubmissionResult.toHighQuantity:
                    claimValidationModalDescription =
                        'Claiming quantity exceeds the ordered quantity.';
                    break;
                case ClaimSubmissionResult.claimingProductDoesNotExist:
                    claimValidationModalDescription =
                        'Claiming product does not exist on the order.';
                    break;
                case ClaimSubmissionResult.productNotFound:
                    claimValidationModalDescription =
                        'Claiming product was not found.';
                    break;
                case ClaimSubmissionResult.orderNotFound:
                    claimValidationModalDescription =
                        'The order in which you claimed the product does not exist.';
                    break;
                case ClaimSubmissionResult.portRequired:
                    claimValidationModalDescription =
                        'Port is required for provided problem.';
                    break;
                default:
                    claimValidationModalDescription = 'Something went wrong';
            }

            openGlobalModal({
                modalType: ModalType.claimValidationModal,
                claimValidationModalDetails: {
                    title: claimValidationModalTitle,
                    description: claimValidationModalDescription,
                    claimDetails: failedClaim,
                },
            });
        }
    };

    const handleWorkboxEvent = async (event: any) => {
        const data = event.data;

        if (!data || !isValidMessageFromWorkbox(data)) {
            console.error('Invalid MessageSW');
            return;
        }

        const { messageType } = data;

        const refreshData = async () => {
            const requestType = getRequestType(data.body as BodyCopy);

            if (
                requestType === RequestType.claim ||
                requestType === RequestType.file
            ) {
                await handleClaimRequest(
                    data,
                    queueStatus.onlineQueue,
                    queueStatus.offlineQueue,
                );
                await refreshClaims();
            }

            if (requestType === RequestType.manning) {
                await refreshManningReports();
            }

            if (requestType === RequestType.stocktaking) {
                await revalidateStocktakingReports();
            }

            if (requestType === RequestType.cashPurchase) {
                await revalidateCashPurchaseOrders();
            }
        };
        switch (messageType) {
            case MessageType.newRequestInOfflineQueue:
                await getOfflineQueueContents();
                break;
            case MessageType.requestSent:
                await getOfflineQueueContents();
                await getOnlineQueueContents();

                await refreshData();

                break;
            case MessageType.newRequestInOnlineQueue:
                await getOnlineQueueContents();
                break;
            case MessageType.unavailableItemsErrorOnRequest:
                if (data.localId && data.type) {
                    await revertOfflineOrderToDraftStage(data.localId);
                    const metadata = await getEditableOrderById(data.localId);
                    putFailedRequestModalMetadata({
                        orderToDisplay: metadata,
                        isUnavailableItemModal: true,
                        unavailableItemModalMessage:
                            'At least one of your offline orders contains unavailable products and could not be sent. Please exchange unavailable products with similar items and submit your order(s) again.',
                        unavailableItemButtonText: 'Review',
                    });
                }
                break;
            case MessageType.requestRemovedByUi:
                await getOfflineQueueContents();
                await getOnlineQueueContents();
                break;
            case MessageType.claimValidationFailed:
                await onClaimValidationFailed(data);
                break;
        }
    };

    useEffect(() => {
        getOnlineQueueContents();
        getOfflineQueueContents();

        if (!notificationWasHiddenByUser && !isOnline) {
            setShowOfflineNotification(true);
        } else {
            setShowOfflineNotification(false);
            window.workbox.messageSW({
                command: swMessages.sendRequestsFromDb,
            });
        }
    }, [isOnline, notificationWasHiddenByUser]);

    useEffect(() => {
        updateConnectionStatus();
        getOnlineQueueContents();
        getOfflineQueueContents();

        window.addEventListener('online', updateConnectionStatus);
        window.addEventListener('offline', updateConnectionStatus);
        if (window.workbox) {
            window.workbox.addEventListener('message', handleWorkboxEvent);
        }

        return () => {
            window.removeEventListener('online', updateConnectionStatus);
            window.removeEventListener('offline', updateConnectionStatus);

            if (window.workbox) {
                window.workbox.removeEventListener(
                    'message',
                    handleWorkboxEvent,
                );
            }
        };
    }, []);

    return (
        <SyncStatusContext.Provider
            value={{
                isOnline,
                queueStatus,
                showOfflineNotification,
                hideNotificationPermanently,
                revertOfflineOrderToDraftStage,
                putFailedRequestModalMetadata,
                failedRequestModalMetadata,
            }}
        >
            {children}
        </SyncStatusContext.Provider>
    );
};

export const useSyncStatus = () => {
    const context = useContext(SyncStatusContext);
    if (context === undefined) {
        throw new Error('useSyncStatus context is out of scope');
    }

    return context;
};

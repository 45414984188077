import { Portal } from '@radix-ui/react-portal';
import OutsideClickHandler from 'react-outside-click-handler';
import { useEffect, useRef, useState } from 'react';
import { useAppLoading } from '../../context/AppLoadingContext';
import { useSyncStatus } from '../../context/SyncStatusContext';
import { FlexSpacer } from '../FlexSpacer/FlexSpacer';
import SearchBox from '../SearchBox/SearchBox';
import { ConnectionStatusIndicator } from '../SyncSidebarIndicators/ConnectionStatusIndicator/ConnectionStatusIndicator';
import { SyncStatusPopup } from '../SyncStatusPopup/SyncStatusPopup';
import {
    Notification,
    NotificationType,
} from '../SyncStatusPopup/Notification';
import styles from './PageTopbar.module.css';
import { useSearch } from '../../context/SearchContext';
import { useRouter } from 'next/router'; 

const PageTopbar: React.FC = () => {
    const { searchQuery, handleChangeQuery } = useSearch(); 
    const { showOfflineNotification, hideNotificationPermanently } =
        useSyncStatus();

    const { query, asPath } = useRouter();

    const isPageStocktaking = asPath === `/stocktaking/${query.monthAndYear}`;
    const isPageSeason = asPath.includes('/season')
    const isOrderCreationFlow = asPath.includes('/order/');

    const [isPageScrolled, setIsPageScrolled] = useState(false);

    const handleStickyTopbar = () => {
        const scrollYPosition = window.scrollY;

        if (scrollYPosition > 25) {
            setIsPageScrolled(true);
        } else {
            setIsPageScrolled(false);
        }
    };

    useEffect(() => {
        window?.addEventListener('scroll', handleStickyTopbar, true);
        return () => {
            window?.removeEventListener('scroll', handleStickyTopbar, true);
        };
    }, []);

    const [syncStatusPopup, setSyncStatusPopup] = useState(false);

    const [showPricesLoader, setShowPricesLoader] = useState(false);

    const { loadingPrices } = useAppLoading();

    const interval = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (loadingPrices) {
            interval.current = setTimeout(() => {
                setShowPricesLoader(loadingPrices);
            }, 1000);
        } else {
            setShowPricesLoader(false);
            if (interval.current) {
                clearInterval(interval.current);
            }
        }

        return () => {
            if (interval.current) {
                clearInterval(interval.current);
            }
        };
    }, [loadingPrices]);

    // Seasonal Order Search state and logic
    const [isTooltipVisible, setIsTooltipVisible] = useState(true);
    const [isGlobalSearch, setIsGlobalSearch] = useState(false);

    return (
        <>
            {syncStatusPopup && (
                <Portal>
                    <OutsideClickHandler
                        onOutsideClick={() => {
                            setSyncStatusPopup(false);
                        }}
                    >
                        <SyncStatusPopup />
                    </OutsideClickHandler>
                </Portal>
            )}
            {showPricesLoader && (
                <Portal>
                    <Notification
                        type={NotificationType.loadingPrices}
                        onClose={() => {
                            setShowPricesLoader(false);
                        }}
                    />
                </Portal>
            )}
            {showOfflineNotification && (
                <Portal>
                    <Notification
                        type={NotificationType.connectionLost}
                        onClose={hideNotificationPermanently}
                    />
                </Portal>
            )}

            <div
                className={`${styles.container} ${
                    isPageScrolled ? styles.sticky : ''
                }`}
            >
                {(
                    isPageStocktaking || isOrderCreationFlow || isPageSeason) && (
                    <div className={styles.searchAreaContainer}> {
                            isPageSeason ? (

                                <div
                                    className={styles.assortmentToggleContainer}
                                    onClick={() => {
                                        setIsTooltipVisible(false);
                                    }}
                                >
                                    {/* <HelperTooltip
                                        tooltipTitle={'New search functionality added!'}
                                        tooltipMessage={
                                            'Click on the search icon at the top of this page and start typing to search through your potential stock only.\n' +
                                            'If you wish to see items within your assortment but excluding the potential stock, click on the toggle button below the search icon this will turn green.\n' +
                                            'You can switch between search modes at any point.'
                                        }
                                        isVisible={isTooltipVisible}
                                    > */}
                                        <SearchBox
                                            searchQuery={searchQuery}
                                            handleChangeQuery={(query) =>
                                                handleChangeQuery(query, {
                                                    showInLayout: isOrderCreationFlow,
                                                })
                                            }
                                        />
                                    {/* </HelperTooltip> */}
                                </div>
                            ) : (
                                <SearchBox
                                    searchQuery={searchQuery}
                                    handleChangeQuery={(query) =>
                                        handleChangeQuery(query, {
                                            showInLayout: isOrderCreationFlow,
                                        })
                                    }
                                />

                            )
                        }
                    </div>
                )}
                 
                <FlexSpacer />
                <div className={styles.syncIconsContainer}>
                    {/*
                    //we had this component in figma, but it turned out that designers are still working on it
                       <NotificationBell />
                    */}
                    <span className={styles.horizontalSpacer} />
                    <button
                        className={styles.bellButtonContainer}
                        onClick={() => {
                            setSyncStatusPopup(true);
                        }}
                    >
                        <ConnectionStatusIndicator primary />
                    </button>
                </div>
            </div>
            <div className={styles.spacer} />
        </>
    );
};

export default PageTopbar;
import styles from './CreateNewOrderButton.module.css';
import React from 'react';
import AddIcon from '../../../public/gfx/uiIcons/add.svg';
import { OrderTypes } from '../../../context/OrderTypes';
import order from '../../../pages/order';

interface Props {
    onClick: () => void;
    orderType: OrderTypes;
}

export const CreateNewOrderButton: React.FC<Props> = ({
    onClick,
    orderType,
}) => {
    const orderTypeText = [OrderTypes.season].includes(orderType)
        ? 'Season'
        : orderType 
    
    return (
        <div onClick={onClick} className={styles.buttonContainer}>
            <div className={styles.textContainer}>
                <p>create new</p>
                <h3>{orderTypeText} Order</h3>
            </div>
            <div className={styles.addButton}>
                <AddIcon stroke="#fff" width="12" height="12" />
            </div>
        </div>
    );
};

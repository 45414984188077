import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import styles from './CategoriesBar.module.css';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { ProductCategory } from '../ProductsList';
import Button from '../../Button/Button';
import _debounce from 'debounce';
import ArrowNavigator from './ArrowNavigator';
import { isTouchDevice } from '../../utils/isTouchDevice';
import { StocktakingProductCategoryToDisplay } from '../../StocktakingProductsList/utils/stocktakingUtils';
import { Product } from '../../../types/order.types';

export interface CategoriesBarProps {
    productsSortedByCategories:
        | ProductCategory<Product>[]
        | StocktakingProductCategoryToDisplay[];
    selectCategory: (categoryName: string, changedManually: boolean) => void;
    selectedCategory: {
        categoryName: string;
        changedManually: boolean;
    };
    productListScrolledToTheEnd: boolean;
    isCategoriesBarSticky?: boolean;
    categoriesBarsScrollLeft: number;
    setCategoriesBarsScrollLeft: Dispatch<SetStateAction<number>>;
}

export const CategoriesBar: React.FunctionComponent<CategoriesBarProps> = ({
    productsSortedByCategories,
    selectedCategory,
    selectCategory,
    productListScrolledToTheEnd,
    isCategoriesBarSticky,
    categoriesBarsScrollLeft,
    setCategoriesBarsScrollLeft,
}) => {
    const [overflowLeft, setOverflowLeft] = useState<'active' | undefined>();
    const [overflowRight, setOverflowRight] = useState<'active' | undefined>(
        'active',
    );

    const [scrollWrapperElement, setScrollWrapperElement] = useState<
        HTMLDivElement | undefined
    >();
    const [categoriesWrapperRef, setCategoriesWrapperRef] = useState<
        HTMLDivElement | undefined
    >();

    const restoreLastPositionOnRerender = () => {
        if (!scrollWrapperElement) {
            return;
        }

        scrollWrapperElement.style.scrollBehavior = 'auto';
        scrollWrapperElement.scrollTo({
            left: categoriesBarsScrollLeft,
        });
        scrollWrapperElement.style.scrollBehavior = 'smooth';
    };

    const debouncedPersistScrollPosition = _debounce(() => {
        if (!scrollWrapperElement) {
            return;
        }
        setCategoriesBarsScrollLeft(scrollWrapperElement.scrollLeft);
    });

    useEffect(() => {
        if (!scrollWrapperElement) {
            return;
        }
        restoreLastPositionOnRerender();

        scrollWrapperElement.addEventListener(
            'scroll',
            debouncedPersistScrollPosition,
        );

        return () => {
            scrollWrapperElement.removeEventListener(
                'scroll',
                debouncedPersistScrollPosition,
            );
        };
    }, [scrollWrapperElement]);

    const isContentBiggerThanViewport = () => {
        if (
            categoriesWrapperRef?.clientWidth &&
            scrollWrapperElement?.offsetWidth
        ) {
            return (
                categoriesWrapperRef?.clientWidth >
                scrollWrapperElement?.offsetWidth
            );
        }
    };

    const updateEdges = useCallback(() => {
        if (
            !scrollWrapperElement ||
            !scrollWrapperElement ||
            !categoriesWrapperRef
        ) {
            return;
        }
        // Prepare elements to inspect for edge shadow / gradient placement
        const scrollLeft = scrollWrapperElement?.scrollLeft ?? 0;
        const viewportWidth = scrollWrapperElement?.offsetWidth ?? 0;
        const contentWidth = categoriesWrapperRef?.clientWidth ?? 0;
        const scrollBarWidth = 13;
        // Content is bigger than viewport
        if (contentWidth >= viewportWidth) {
            // Set shadow on the right active, if viewport isn't scrolled all the way to the right
            setOverflowRight(
                scrollLeft + viewportWidth < contentWidth - scrollBarWidth
                    ? 'active'
                    : undefined,
            );
            // Set shadow on the left active, if viewport isn't scrolled all the way to the left
            setOverflowLeft(scrollLeft > 10 ? 'active' : undefined);
        } else {
            // Content fits viewport, disable shadows
            setOverflowRight(undefined);
            setOverflowLeft(undefined);
        }
    }, [categoriesWrapperRef, scrollWrapperElement]);

    useEffect(() => {
        if (scrollWrapperElement) {
            // Attach scroll listener to update shadows on scroll
            scrollWrapperElement.addEventListener('scroll', updateEdges);
            // Update edges when resizing window
            window.addEventListener('resize', updateEdges);
            // Update on load
            updateEdges();

            return () => {
                scrollWrapperElement.removeEventListener('scroll', updateEdges);
                window.removeEventListener('resize', updateEdges);
            };
        }
        return undefined;
    }, [scrollWrapperElement]);

    useEffect(() => {
        // Update scroll properties when filters changes
        updateEdges();
    }, [productsSortedByCategories]);

    const scrollAreaWrapperCallbackRef = useCallback((node: HTMLDivElement) => {
        setScrollWrapperElement(
            node?.querySelector<HTMLDivElement>(
                '[data-radix-scroll-area-viewport]',
            ) ?? undefined,
        );
        setCategoriesWrapperRef(
            node?.querySelector<HTMLDivElement>(
                '[data-radix-scroll-area-viewport] > div',
            ) ?? undefined,
        );
    }, []);

    const scrollCategoriesBarToKeepSelectedVisible = () => {
        if (selectedCategory.changedManually) {
            return;
        }
        if (!isCategoriesBarSticky) {
            return;
        }

        const selectedCategoryButtonEl = document.getElementById(
            `categoryButton-${selectedCategory.categoryName}`,
        );

        if (!selectedCategoryButtonEl) {
            return;
        }

        const offsetEl = selectedCategoryButtonEl?.offsetLeft;

        scrollWrapperElement?.scrollTo({
            left: offsetEl - scrollWrapperElement.clientWidth / 2,
        });
    };

    useEffect(() => {
        scrollCategoriesBarToKeepSelectedVisible();
    }, [selectedCategory]);

    const scrollCategoriesBarToTheEnd = () => {
        scrollWrapperElement?.scrollBy(scrollWrapperElement.clientWidth, 0);
    };

    const scrollCategoriesBarToTheRight = () => {
        scrollWrapperElement?.scrollBy(scrollWrapperElement.clientWidth / 2, 0);
    };

    const scrollCategoriesBarToTheLeft = () => {
        scrollWrapperElement?.scrollBy(
            -scrollWrapperElement.clientWidth / 2,
            0,
        );
    };

    useEffect(() => {
        if (productListScrolledToTheEnd) {
            scrollCategoriesBarToTheEnd();
        }
    }, [productListScrolledToTheEnd]);

    return (
        <ScrollArea.Root
            ref={scrollAreaWrapperCallbackRef}
            className={[
                styles.container,
                overflowLeft ? styles.overflowLeft : '',
                overflowRight ? styles.overflowRight : '',
                !isTouchDevice() ? styles.margins : '',
            ].join(' ')}
        >
            {!isTouchDevice() && isContentBiggerThanViewport() && (
                <ArrowNavigator
                    left
                    overflowLeft={overflowLeft}
                    actionOnClick={scrollCategoriesBarToTheLeft}
                />
            )}
            <ScrollArea.Viewport style={{ scrollBehavior: 'smooth' }}>
                <div style={{ display: 'flex' }}>
                    {productsSortedByCategories.map((item) => {
                        const isSelected =
                            selectedCategory.categoryName.toLowerCase() ===
                            item.categoryName.toLowerCase();
                        return (
                            <Button
                                id={`categoryButton-${item.categoryName}`}
                                key={item.categoryName}
                                smallRounded
                                thin
                                lightBlue={isSelected}
                                textButton={!isSelected}
                                text={item.categoryName.toLowerCase()}
                                noWrap
                                capitalize
                                onClick={() => {
                                    selectCategory(item.categoryName, true)
                                }}
                            />
                        );
                    })}
                </div>
            </ScrollArea.Viewport>
            <ScrollArea.ScrollAreaScrollbar orientation="horizontal">
                <ScrollArea.Thumb />
            </ScrollArea.ScrollAreaScrollbar>
            {!isTouchDevice() && isContentBiggerThanViewport() && (
                <ArrowNavigator
                    right
                    overflowRight={overflowRight}
                    actionOnClick={scrollCategoriesBarToTheRight}
                />
            )}
        </ScrollArea.Root>
    );
};
